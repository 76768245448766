import {
  graphQLSelector,
  GraphQLReturn,
  selectorFamily,
} from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { recoilGetFilterOptionsQuery } from './__generated__/recoilGetFilterOptionsQuery.graphql';
import { Filter } from '../../consts';
import { monitorSubmissionsFilterState } from '../../recoil';
import moment from 'moment';
import { getStageLabelFromId } from '../../utils';
import { groupBy, map } from 'lodash';

const removeNulls = <T>(value: T): value is NonNullable<T> => Boolean(value);

const $filterOptions = graphQLSelector({
  query: graphql`
    query recoilGetFilterOptionsQuery {
      type: sys_submissions(
        distinct_on: submission_type_id
        where: { is_closed: { _eq: false } }
      ) {
        submission_type @required(action: THROW) {
          submission_type_label
          submission_type_id
        }
      }
      fund: sys_properties(
        distinct_on: fund
        where: { is_active: { _eq: true } }
      ) {
        fund
      }
      provider: sys_providers(
        distinct_on: provider_id
        where: { is_active: { _eq: true } }
      ) {
        provider_name
        provider_id
      }
      batch: sys_submissions(
        distinct_on: submission_id
        where: { is_closed: { _eq: false } }
      ) {
        submission_name
        submission_id
      }
      stage: sys_property_batch_stages(distinct_on: property_batch_stage_id) {
        property_batch_stage_id
        property_batch_stage_description
      }
    }
  ` as GraphQLReturn<recoilGetFilterOptionsQuery>,
  mapVariables: () => () => ({}),
});

export const $filterOption = selectorFamily<
  { label: string; value: string | number | number[] }[],
  Filter
>({
  key: 'monitor-submissions-filters/filter-option',
  scoped: true,
  get:
    (filter: Filter) =>
    ({ get }) => {
      switch (filter) {
        case 'Type': {
          const result = get($filterOptions());
          return result.type.map((t) => ({
            label: t.submission_type.submission_type_label,
            value: t.submission_type.submission_type_id,
          }));
        }
        case 'Fund': {
          const result = get($filterOptions());
          const funds = result.fund
            .map((f) => f.fund)
            .filter<string>(removeNulls);
          return funds.map((f) => ({ label: f, value: f }));
        }
        case 'Status': {
          return [
            { label: 'Not Started', value: 'Not Started' },
            { label: 'In Progress', value: 'Open' },
            { label: 'Stuck', value: 'Stuck' },
            { label: 'Approved', value: 'Approved' },
            { label: 'On Time', value: 'On Time' },
            { label: 'Past Due', value: 'Past Due' },
          ];
        }
        case 'Interval': {
          return [
            { label: 'Monthly', value: 'monthly' },
            { label: 'Quarterly', value: 'quarterly' },
          ];
        }
        case 'Period': {
          const state = get(
            monitorSubmissionsFilterState('Interval')
          ) as string;
          const unit = {
            monthly: 'month' as const,
            quarterly: 'quarter' as const,
          }[state];
          if (!unit) {
            return [];
          }
          const format = {
            month: 'MMM YYYY',
            quarter: '[Q]Q YYYY',
          }[unit];
          const startOfUnit = moment().startOf(unit);
          return Array.from({ length: 6 }).map((_, i) => {
            const optionDate = moment(startOfUnit).add(i - 4, unit);
            return {
              label: optionDate.format(format),
              value: optionDate.format('YYYY-MM-DD'),
            };
          });
        }
        case 'Provider': {
          const result = get($filterOptions());
          return result.provider.map((p) => ({
            label: p.provider_name,
            value: p.provider_id,
          }));
        }
        case 'Stage': {
          const result = get($filterOptions());

          return map(
            groupBy(
              result?.stage?.map((p) => ({
                label: getStageLabelFromId(p.property_batch_stage_id),
                value: p.property_batch_stage_id,
              })) || [],
              'label'
            ),
            (values, label) => ({
              label,
              value: map(values, 'value').join(','),
            })
          );
        }
        case 'Validation': {
          return [{ label: 'Failed', value: 'failed' }];
        }
        case 'Batch': {
          const result = get($filterOptions());
          return result.batch.map((b) => ({
            label: b.submission_name,
            value: b.submission_id,
          }));
        }
        default:
          throw new Error(`unknown filter ${filter}`);
      }
    },
});
