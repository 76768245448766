/**
 * @generated SignedSource<<dd7eee188b0bfa828c03dec209a94fa4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type sakura_organization_permission_select_column = "id" | "name" | "organization_id" | "%future added value";
export type sakura_user_permission_select_column = "id" | "name" | "user_id" | "%future added value";
export type sakura_user_select_column = "attributes" | "auth0_synced" | "create_time" | "dashboards" | "defaultPowerBiReportId" | "disabled" | "email" | "email_ci" | "first_name" | "id" | "last_modified" | "last_name" | "mongo_id" | "organization_id" | "role" | "%future added value";
export type sakura_user_select_column_sakura_user_aggregate_bool_exp_bool_and_arguments_columns = "auth0_synced" | "disabled" | "%future added value";
export type sakura_user_select_column_sakura_user_aggregate_bool_exp_bool_or_arguments_columns = "auth0_synced" | "disabled" | "%future added value";
export type sys_batch_files_constraint = "batch_files_file_id_dataset_id_key" | "pk_batch_files" | "%future added value";
export type sys_batch_files_select_column = "created_at_datetime" | "dataset_id" | "file_id" | "file_name" | "file_reference_souce" | "file_source" | "organization_id" | "processed_row_count" | "row_count" | "submitter_id" | "updated_at_datetime" | "%future added value";
export type sys_batch_files_update_column = "created_at_datetime" | "dataset_id" | "file_id" | "file_name" | "file_reference_souce" | "file_source" | "organization_id" | "processed_row_count" | "row_count" | "submitter_id" | "updated_at_datetime" | "%future added value";
export type sys_batch_validation_rules_constraint = "batch_validation_rules_batch_validation_rule_id_organizatio_key" | "batch_validation_rules_pkey" | "batch_validation_rules_validation_slug_organization_id_key" | "%future added value";
export type sys_batch_validation_rules_update_column = "banner_config" | "batch_validation_rule_id" | "created_at_datetime" | "feature_flag" | "is_active" | "organization_id" | "organization_params" | "report_id" | "updated_at_datetime" | "validation_description" | "validation_label" | "validation_params" | "validation_slug" | "validation_type" | "%future added value";
export type sys_comment_types_constraint = "comment_types_comment_type_slug_key" | "comment_types_pkey" | "%future added value";
export type sys_comment_types_update_column = "comment_type_id" | "comment_type_slug" | "%future added value";
export type sys_comments_constraint = "comments_pkey" | "%future added value";
export type sys_comments_select_column = "author_id" | "comment_id" | "comment_type_id" | "created_at_datetime" | "package_report_id" | "property_batch_id" | "text" | "%future added value";
export type sys_comments_update_column = "author_id" | "comment_id" | "comment_type_id" | "created_at_datetime" | "package_report_id" | "property_batch_id" | "text" | "%future added value";
export type sys_dataset_statuses_constraint = "pk_dataset_statuses" | "%future added value";
export type sys_dataset_statuses_update_column = "created_at_datetime" | "dataset_status_description" | "dataset_status_id" | "updated_at_datetime" | "%future added value";
export type sys_dataset_validation_rules_constraint = "dataset_validation_rules_pkey" | "dataset_validation_rules_validation_slug_dataset_id_key" | "%future added value";
export type sys_dataset_validation_rules_select_column = "banner_config" | "created_at_datetime" | "dataset_id" | "dataset_validation_rule_id" | "feature_flag" | "is_active" | "organization_id" | "report_id" | "updated_at_datetime" | "validation_description" | "validation_label" | "validation_params" | "validation_slug" | "validation_type" | "%future added value";
export type sys_dataset_validation_rules_select_column_sys_dataset_validation_rules_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_dataset_validation_rules_select_column_sys_dataset_validation_rules_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_dataset_validation_rules_update_column = "banner_config" | "created_at_datetime" | "dataset_id" | "dataset_validation_rule_id" | "feature_flag" | "is_active" | "organization_id" | "report_id" | "updated_at_datetime" | "validation_description" | "validation_label" | "validation_params" | "validation_slug" | "validation_type" | "%future added value";
export type sys_datasets_batch_validation_rules_constraint = "datasets_batch_validation_rul_dataset_id_batch_validation_r_key" | "datasets_batch_validation_rules_pkey" | "%future added value";
export type sys_datasets_batch_validation_rules_select_column = "batch_validation_rule_id" | "dataset_batch_validation_rule_id" | "dataset_id" | "is_active" | "organization_id" | "%future added value";
export type sys_datasets_batch_validation_rules_select_column_sys_datasets_batch_validation_rules_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_datasets_batch_validation_rules_select_column_sys_datasets_batch_validation_rules_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_datasets_batch_validation_rules_update_column = "batch_validation_rule_id" | "dataset_batch_validation_rule_id" | "dataset_id" | "is_active" | "organization_id" | "%future added value";
export type sys_datasets_constraint = "datasets_dataset_id_organization_id_key" | "datasets_dataset_slug_organization_id_key" | "pk_datasets" | "%future added value";
export type sys_datasets_select_column = "created_at_datetime" | "dataset_description" | "dataset_id" | "dataset_label" | "dataset_object" | "dataset_slug" | "feature_flag" | "flatfile_embed_id" | "flatfile_private_key" | "is_tsa_enabled" | "managed_by" | "organization_id" | "submission_type_id" | "tsa_flatfile_embed_id" | "tsa_flatfile_private_key" | "unified_ingest_schemas" | "updated_at_datetime" | "%future added value";
export type sys_datasets_select_column_sys_datasets_aggregate_bool_exp_bool_and_arguments_columns = "is_tsa_enabled" | "%future added value";
export type sys_datasets_select_column_sys_datasets_aggregate_bool_exp_bool_or_arguments_columns = "is_tsa_enabled" | "%future added value";
export type sys_datasets_update_column = "created_at_datetime" | "dataset_description" | "dataset_id" | "dataset_label" | "dataset_object" | "dataset_slug" | "feature_flag" | "flatfile_embed_id" | "flatfile_private_key" | "is_tsa_enabled" | "managed_by" | "organization_id" | "submission_type_id" | "tsa_flatfile_embed_id" | "tsa_flatfile_private_key" | "unified_ingest_schemas" | "updated_at_datetime" | "%future added value";
export type sys_mapping_fields_constraint = "mapping_fields_mapping_field_name_organization_id_key" | "pk_mapping_fields" | "%future added value";
export type sys_mapping_fields_select_column = "created_at_datetime" | "custom_attributes" | "is_active" | "is_single_mapping_set" | "mapping_field_description" | "mapping_field_id" | "mapping_field_name" | "organization_id" | "updated_at_datetime" | "%future added value";
export type sys_mapping_fields_select_column_sys_mapping_fields_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "is_single_mapping_set" | "%future added value";
export type sys_mapping_fields_select_column_sys_mapping_fields_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "is_single_mapping_set" | "%future added value";
export type sys_mapping_fields_update_column = "created_at_datetime" | "custom_attributes" | "is_active" | "is_single_mapping_set" | "mapping_field_description" | "mapping_field_id" | "mapping_field_name" | "organization_id" | "updated_at_datetime" | "%future added value";
export type sys_mapping_set_values_constraint = "mapping_set_values_mapping_set_id_source_mapping_code_key" | "mapping_set_values_pkey" | "%future added value";
export type sys_mapping_set_values_select_column = "created_at_datetime" | "custom_attributes" | "is_active" | "mapping_set_id" | "mapping_set_value_id" | "organization_id" | "source_mapping_code" | "source_mapping_label" | "target_mapping_code" | "target_mapping_label" | "updated_at_datetime" | "%future added value";
export type sys_mapping_set_values_select_column_sys_mapping_set_values_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_mapping_set_values_select_column_sys_mapping_set_values_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_mapping_set_values_update_column = "created_at_datetime" | "custom_attributes" | "is_active" | "mapping_set_id" | "mapping_set_value_id" | "organization_id" | "source_mapping_code" | "source_mapping_label" | "target_mapping_code" | "target_mapping_label" | "updated_at_datetime" | "%future added value";
export type sys_mapping_sets_constraint = "mapping_sets_mapping_set_id_organization_id_key" | "mapping_sets_mapping_set_id_organization_id_mapping_field_id_ke" | "mapping_sets_pkey" | "%future added value";
export type sys_mapping_sets_select_column = "created_at_datetime" | "is_active" | "mapping_field_id" | "mapping_set_description" | "mapping_set_id" | "master_chart_of_account_id" | "organization_id" | "provider_id" | "updated_at_datetime" | "%future added value";
export type sys_mapping_sets_select_column_sys_mapping_sets_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_mapping_sets_select_column_sys_mapping_sets_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_mapping_sets_update_column = "created_at_datetime" | "is_active" | "mapping_field_id" | "mapping_set_description" | "mapping_set_id" | "master_chart_of_account_id" | "organization_id" | "provider_id" | "updated_at_datetime" | "%future added value";
export type sys_notification_recipients_constraint = "notification_recipients_pkey" | "%future added value";
export type sys_notification_recipients_select_column = "failed_count" | "last_failed_attempt_at_datetime" | "notification_id" | "sent_at_datetime" | "user_id" | "%future added value";
export type sys_notification_recipients_update_column = "failed_count" | "last_failed_attempt_at_datetime" | "notification_id" | "sent_at_datetime" | "user_id" | "%future added value";
export type sys_notification_types_constraint = "notification_types_notification_type_slug_key" | "notification_types_pkey" | "%future added value";
export type sys_notification_types_update_column = "notification_type_id" | "notification_type_slug" | "priority" | "%future added value";
export type sys_notifications_constraint = "notifications_pkey" | "%future added value";
export type sys_notifications_select_column = "created_at_datetime" | "notification_detail_data" | "notification_group_data" | "notification_id" | "notification_source_data" | "notification_type_id" | "%future added value";
export type sys_notifications_update_column = "created_at_datetime" | "notification_detail_data" | "notification_group_data" | "notification_id" | "notification_source_data" | "notification_type_id" | "%future added value";
export type sys_organization_account_tree_details_constraint = "pk_organization_account_tree_details" | "%future added value";
export type sys_organization_account_tree_details_select_column = "account_code_description" | "account_group_code" | "account_tree_id" | "account_type" | "chart_of_account_id" | "created_at_datetime" | "gl_account_code" | "indent" | "is_contiguous" | "is_hidden" | "is_negate" | "is_normal_balance" | "nest_level" | "order_index" | "organization_id" | "parent_chart_of_account_id" | "total_into_code" | "tree_path" | "updated_at_datetime" | "%future added value";
export type sys_organization_account_tree_details_select_column_sys_organization_account_tree_details_aggregate_bool_exp_bool_and_arguments_columns = "is_contiguous" | "is_hidden" | "is_negate" | "is_normal_balance" | "%future added value";
export type sys_organization_account_tree_details_select_column_sys_organization_account_tree_details_aggregate_bool_exp_bool_or_arguments_columns = "is_contiguous" | "is_hidden" | "is_negate" | "is_normal_balance" | "%future added value";
export type sys_organization_account_tree_details_update_column = "account_code_description" | "account_group_code" | "account_tree_id" | "account_type" | "chart_of_account_id" | "created_at_datetime" | "gl_account_code" | "indent" | "is_contiguous" | "is_hidden" | "is_negate" | "is_normal_balance" | "nest_level" | "order_index" | "organization_id" | "parent_chart_of_account_id" | "total_into_code" | "tree_path" | "updated_at_datetime" | "%future added value";
export type sys_organization_account_trees_constraint = "pk_organization_account_trees" | "%future added value";
export type sys_organization_account_trees_update_column = "account_tree_name" | "created_at_datetime" | "organization_account_tree_id" | "organization_id" | "updated_at_datetime" | "%future added value";
export type sys_organization_master_chart_of_account_values_constraint = "organization_master_chart_of__master_chart_of_account_id_ac_key" | "pk_organization_master_chart_of_account_values" | "%future added value";
export type sys_organization_master_chart_of_account_values_select_column = "account_code" | "account_hold" | "account_name" | "created_at_datetime" | "is_active" | "master_chart_of_account_id" | "master_chart_of_account_values_id" | "organization_id" | "updated_at_datetime" | "%future added value";
export type sys_organization_master_chart_of_account_values_select_column_sys_organization_master_chart_of_account_values_aggregate_bool_exp_bool_and_arguments_columns = "account_hold" | "is_active" | "%future added value";
export type sys_organization_master_chart_of_account_values_select_column_sys_organization_master_chart_of_account_values_aggregate_bool_exp_bool_or_arguments_columns = "account_hold" | "is_active" | "%future added value";
export type sys_organization_master_chart_of_account_values_update_column = "account_code" | "account_hold" | "account_name" | "created_at_datetime" | "is_active" | "master_chart_of_account_id" | "master_chart_of_account_values_id" | "organization_id" | "updated_at_datetime" | "%future added value";
export type sys_organization_master_chart_of_accounts_constraint = "pk_organization_master_chart_of_accounts" | "%future added value";
export type sys_organization_master_chart_of_accounts_update_column = "chart_of_accounts_description" | "chart_of_accounts_name" | "created_at_datetime" | "is_active" | "master_chart_of_account_id" | "organization_id" | "updated_at_datetime" | "%future added value";
export type sys_organizations_constraint = "organizations_organization_slug_key" | "organizations_pkey" | "%future added value";
export type sys_organizations_select_column = "created_at_datetime" | "flatfile_team_name" | "organization_description" | "organization_id" | "organization_label" | "organization_slug" | "property_model_id" | "settings" | "updated_at_datetime" | "%future added value";
export type sys_organizations_update_column = "created_at_datetime" | "flatfile_team_name" | "organization_description" | "organization_id" | "organization_label" | "organization_slug" | "property_model_id" | "settings" | "updated_at_datetime" | "%future added value";
export type sys_package_reports_constraint = "package_reports_package_report_id_organization_id_key" | "package_reports_package_report_slug_organization_id_key" | "package_reports_pkey" | "%future added value";
export type sys_package_reports_datasets_constraint = "package_reports_datasets_package_report_id_dataset_id_key" | "package_reports_datasets_pkey" | "%future added value";
export type sys_package_reports_datasets_select_column = "dataset_id" | "package_report_dataset_id" | "package_report_id" | "%future added value";
export type sys_package_reports_datasets_update_column = "dataset_id" | "package_report_dataset_id" | "package_report_id" | "%future added value";
export type sys_package_reports_update_column = "created_at_datetime" | "is_active" | "organization_id" | "organization_params" | "package_report_description" | "package_report_id" | "package_report_label" | "package_report_slug" | "report_id" | "updated_at_datetime" | "%future added value";
export type sys_properties_batch_validation_rules_constraint = "idx_properties_batch_validation_rules_unique_constraint" | "properties_batch_validation_r_property_batch_validation_rul_key" | "properties_batch_validation_rules_pkey" | "%future added value";
export type sys_properties_batch_validation_rules_select_column = "batch_validation_rule_id" | "created_at_datetime" | "is_active" | "organization_id" | "property_batch_validation_rule_id" | "property_id" | "updated_at_datetime" | "validation_mode" | "%future added value";
export type sys_properties_batch_validation_rules_select_column_sys_properties_batch_validation_rules_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_properties_batch_validation_rules_select_column_sys_properties_batch_validation_rules_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_properties_batch_validation_rules_update_column = "batch_validation_rule_id" | "created_at_datetime" | "is_active" | "organization_id" | "property_batch_validation_rule_id" | "property_id" | "updated_at_datetime" | "validation_mode" | "%future added value";
export type sys_properties_constraint = "pk_properties" | "properties_property_id_organization_id_key" | "%future added value";
export type sys_properties_dataset_validation_rules_constraint = "idx_properties_dataset_validation_rules_unique_constraint" | "properties_dataset_validation_rules_pkey" | "%future added value";
export type sys_properties_dataset_validation_rules_select_column = "created_at_datetime" | "dataset_validation_rule_id" | "is_active" | "organization_id" | "property_dataset_validation_rule_id" | "property_id" | "updated_at_datetime" | "validation_mode" | "%future added value";
export type sys_properties_dataset_validation_rules_select_column_sys_properties_dataset_validation_rules_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_properties_dataset_validation_rules_select_column_sys_properties_dataset_validation_rules_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_properties_dataset_validation_rules_update_column = "created_at_datetime" | "dataset_validation_rule_id" | "is_active" | "organization_id" | "property_dataset_validation_rule_id" | "property_id" | "updated_at_datetime" | "validation_mode" | "%future added value";
export type sys_properties_datasets_constraint = "idx_properties_datasets_organization_id_property_id_dataset_id_" | "properties_datasets_pkey" | "%future added value";
export type sys_properties_datasets_select_column = "dataset_id" | "is_active" | "organization_id" | "property_dataset_id" | "property_id" | "template_id" | "%future added value";
export type sys_properties_datasets_select_column_sys_properties_datasets_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_properties_datasets_select_column_sys_properties_datasets_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_properties_datasets_update_column = "dataset_id" | "is_active" | "organization_id" | "property_dataset_id" | "property_id" | "template_id" | "%future added value";
export type sys_properties_mapping_sets_constraint = "idx_properties_mapping_sets_unique_constraint" | "properties_mapping_sets_pkey" | "%future added value";
export type sys_properties_mapping_sets_select_column = "created_at_datetime" | "is_active" | "mapping_field_id" | "mapping_set_id" | "organization_id" | "property_id" | "property_mapping_set_id" | "provider_id" | "updated_at_datetime" | "%future added value";
export type sys_properties_mapping_sets_select_column_sys_properties_mapping_sets_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_properties_mapping_sets_select_column_sys_properties_mapping_sets_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_properties_mapping_sets_update_column = "created_at_datetime" | "is_active" | "mapping_field_id" | "mapping_set_id" | "organization_id" | "property_id" | "property_mapping_set_id" | "provider_id" | "updated_at_datetime" | "%future added value";
export type sys_properties_roles_users_constraint = "properties_roles_users_pkey" | "uk_pru_property_id_property_role_id_user_id_submission_type_id_" | "%future added value";
export type sys_properties_roles_users_select_column = "created_at_datetime" | "is_active" | "organization_id" | "property_id" | "property_role_id" | "property_role_user_id" | "provider_id" | "submission_type_id" | "updated_at_datetime" | "user_id" | "%future added value";
export type sys_properties_roles_users_select_column_sys_properties_roles_users_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_properties_roles_users_select_column_sys_properties_roles_users_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_properties_roles_users_update_column = "created_at_datetime" | "is_active" | "organization_id" | "property_id" | "property_role_id" | "property_role_user_id" | "provider_id" | "submission_type_id" | "updated_at_datetime" | "user_id" | "%future added value";
export type sys_properties_select_column = "address" | "created_at_datetime" | "custom_attributes" | "entity_id" | "entity_name" | "fund" | "is_active" | "is_sold" | "organization_id" | "property_code" | "property_id" | "property_name" | "property_type_id" | "provider_id" | "sold_date" | "type" | "updated_at_datetime" | "%future added value";
export type sys_properties_select_column_sys_properties_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "is_sold" | "%future added value";
export type sys_properties_select_column_sys_properties_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "is_sold" | "%future added value";
export type sys_properties_update_column = "address" | "created_at_datetime" | "custom_attributes" | "entity_id" | "entity_name" | "fund" | "is_active" | "is_sold" | "organization_id" | "property_code" | "property_id" | "property_name" | "property_type_id" | "provider_id" | "sold_date" | "type" | "updated_at_datetime" | "%future added value";
export type sys_property_batch_dataset_validations_constraint = "property_batch_dataset_valida_property_batch_dataset_id_dat_key" | "property_batch_dataset_validations_pkey" | "%future added value";
export type sys_property_batch_dataset_validations_select_column = "dataset_validation_rule_id" | "is_valid" | "organization_id" | "property_batch_dataset_id" | "property_batch_dataset_validation_id" | "validated_datetime" | "validation_mode" | "validation_params" | "%future added value";
export type sys_property_batch_dataset_validations_select_column_sys_property_batch_dataset_validations_aggregate_bool_exp_bool_and_arguments_columns = "is_valid" | "%future added value";
export type sys_property_batch_dataset_validations_select_column_sys_property_batch_dataset_validations_aggregate_bool_exp_bool_or_arguments_columns = "is_valid" | "%future added value";
export type sys_property_batch_dataset_validations_update_column = "dataset_validation_rule_id" | "is_valid" | "organization_id" | "property_batch_dataset_id" | "property_batch_dataset_validation_id" | "validated_datetime" | "validation_mode" | "validation_params" | "%future added value";
export type sys_property_batch_datasets_constraint = "pk_property_batch_datasets" | "property_batch_datasets_property_batch_id_dataset_id_key" | "%future added value";
export type sys_property_batch_datasets_select_column = "approved_datetime" | "created_at_datetime" | "dataset_id" | "dataset_row_count" | "dataset_status_id" | "file_id" | "is_approved" | "is_valid" | "organization_id" | "property_batch_dataset_id" | "property_batch_id" | "updated_at_datetime" | "validated_datetime" | "%future added value";
export type sys_property_batch_datasets_select_column_sys_property_batch_datasets_aggregate_bool_exp_bool_and_arguments_columns = "is_approved" | "is_valid" | "%future added value";
export type sys_property_batch_datasets_select_column_sys_property_batch_datasets_aggregate_bool_exp_bool_or_arguments_columns = "is_approved" | "is_valid" | "%future added value";
export type sys_property_batch_datasets_update_column = "approved_datetime" | "created_at_datetime" | "dataset_id" | "dataset_row_count" | "dataset_status_id" | "file_id" | "is_approved" | "is_valid" | "organization_id" | "property_batch_dataset_id" | "property_batch_id" | "updated_at_datetime" | "validated_datetime" | "%future added value";
export type sys_property_batch_package_reports_constraint = "property_batch_package_report_pkey" | "property_batch_package_reports_package_report_id_property_batch" | "%future added value";
export type sys_property_batch_package_reports_select_column = "is_reviewed" | "organization_id" | "package_report_id" | "property_batch_id" | "property_batch_package_report_id" | "property_batch_stage_id" | "reviewed_datetime" | "user_id" | "%future added value";
export type sys_property_batch_package_reports_select_column_sys_property_batch_package_reports_aggregate_bool_exp_bool_and_arguments_columns = "is_reviewed" | "%future added value";
export type sys_property_batch_package_reports_select_column_sys_property_batch_package_reports_aggregate_bool_exp_bool_or_arguments_columns = "is_reviewed" | "%future added value";
export type sys_property_batch_package_reports_update_column = "is_reviewed" | "organization_id" | "package_report_id" | "property_batch_id" | "property_batch_package_report_id" | "property_batch_stage_id" | "reviewed_datetime" | "user_id" | "%future added value";
export type sys_property_batch_role_statuses_constraint = "property_batch_role_statuses_unique_idx" | "%future added value";
export type sys_property_batch_role_statuses_select_column = "property_batch_stage_id" | "property_role_id" | "%future added value";
export type sys_property_batch_role_statuses_update_column = "property_batch_stage_id" | "property_role_id" | "%future added value";
export type sys_property_batch_stage_transitions_constraint = "pk_property_batch_stage_transitions" | "%future added value";
export type sys_property_batch_stage_transitions_select_column = "action" | "organization_id" | "property_batch_id" | "property_batch_stage_id" | "property_batch_stage_transition_id" | "transition_datetime" | "user_id" | "%future added value";
export type sys_property_batch_stage_transitions_update_column = "action" | "organization_id" | "property_batch_id" | "property_batch_stage_id" | "property_batch_stage_transition_id" | "transition_datetime" | "user_id" | "%future added value";
export type sys_property_batch_stages_constraint = "pk_property_batch_stages" | "%future added value";
export type sys_property_batch_stages_update_column = "created_at_datetime" | "property_batch_stage_description" | "property_batch_stage_id" | "updated_at_datetime" | "%future added value";
export type sys_property_batch_validations_constraint = "property_batch_validations_pkey" | "property_batch_validations_property_batch_id_property_batch_key" | "%future added value";
export type sys_property_batch_validations_select_column = "created_at_datetime" | "is_valid" | "organization_id" | "property_batch_id" | "property_batch_validation_id" | "property_batch_validation_rule_id" | "updated_at_datetime" | "validation_mode" | "validation_params" | "%future added value";
export type sys_property_batch_validations_select_column_sys_property_batch_validations_aggregate_bool_exp_bool_and_arguments_columns = "is_valid" | "%future added value";
export type sys_property_batch_validations_select_column_sys_property_batch_validations_aggregate_bool_exp_bool_or_arguments_columns = "is_valid" | "%future added value";
export type sys_property_batch_validations_update_column = "created_at_datetime" | "is_valid" | "organization_id" | "property_batch_id" | "property_batch_validation_id" | "property_batch_validation_rule_id" | "updated_at_datetime" | "validation_mode" | "validation_params" | "%future added value";
export type sys_property_batches_approved_select_column = "approved_at_datetime" | "created_at_datetime" | "is_approved" | "is_valid" | "property_batch_id" | "property_batch_stage_id" | "property_id" | "submission_id" | "updated_at_datetime" | "validated_at_datetime" | "%future added value";
export type sys_property_batches_approved_select_column_sys_property_batches_approved_aggregate_bool_exp_bool_and_arguments_columns = "is_approved" | "is_valid" | "%future added value";
export type sys_property_batches_approved_select_column_sys_property_batches_approved_aggregate_bool_exp_bool_or_arguments_columns = "is_approved" | "is_valid" | "%future added value";
export type sys_property_batches_constraint = "pk_property_batches" | "property_batches_property_batch_id_organization_id_key" | "%future added value";
export type sys_property_batches_select_column = "approved_at_datetime" | "created_at_datetime" | "is_approved" | "is_valid" | "organization_id" | "property_batch_id" | "property_batch_stage_id" | "property_id" | "submission_id" | "updated_at_datetime" | "validated_at_datetime" | "%future added value";
export type sys_property_batches_select_column_sys_property_batches_aggregate_bool_exp_bool_and_arguments_columns = "is_approved" | "is_valid" | "%future added value";
export type sys_property_batches_select_column_sys_property_batches_aggregate_bool_exp_bool_or_arguments_columns = "is_approved" | "is_valid" | "%future added value";
export type sys_property_batches_update_column = "approved_at_datetime" | "created_at_datetime" | "is_approved" | "is_valid" | "organization_id" | "property_batch_id" | "property_batch_stage_id" | "property_id" | "submission_id" | "updated_at_datetime" | "validated_at_datetime" | "%future added value";
export type sys_property_models_constraint = "property_models_pkey" | "property_models_property_model_slug_key" | "%future added value";
export type sys_property_models_types_constraint = "property_models_types_pkey" | "%future added value";
export type sys_property_models_types_select_column = "approved_property_batch_stage_id" | "created_at_datetime" | "inactivated_at_datetime" | "initial_property_batch_stage_id" | "is_active" | "property_model_id" | "property_model_type_id" | "property_type_id" | "updated_at_datetime" | "%future added value";
export type sys_property_models_types_select_column_sys_property_models_types_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_property_models_types_select_column_sys_property_models_types_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_property_models_types_update_column = "approved_property_batch_stage_id" | "created_at_datetime" | "inactivated_at_datetime" | "initial_property_batch_stage_id" | "property_model_id" | "property_model_type_id" | "property_type_id" | "updated_at_datetime" | "%future added value";
export type sys_property_models_update_column = "created_at_datetime" | "inactivated_at_datetime" | "property_model_id" | "property_model_slug" | "updated_at_datetime" | "%future added value";
export type sys_property_relationships_constraint = "property_relationships_pkey" | "%future added value";
export type sys_property_relationships_select_column = "created_at_datetime" | "inactivated_at_datetime" | "is_active" | "organization_id" | "property_id_from" | "property_id_to" | "property_relationship_id" | "updated_at_datetime" | "%future added value";
export type sys_property_relationships_select_column_sys_property_relationships_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_property_relationships_select_column_sys_property_relationships_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_property_relationships_update_column = "created_at_datetime" | "inactivated_at_datetime" | "organization_id" | "property_id_from" | "property_id_to" | "property_relationship_id" | "updated_at_datetime" | "%future added value";
export type sys_property_roles_constraint = "property_roles_pkey" | "property_roles_property_role_slug_key" | "%future added value";
export type sys_property_roles_update_column = "property_role_id" | "property_role_label" | "property_role_set" | "property_role_slug" | "%future added value";
export type sys_property_types_constraint = "property_types_pkey" | "property_types_property_type_slug_key" | "%future added value";
export type sys_property_types_update_column = "created_at_datetime" | "inactivated_at_datetime" | "property_type_id" | "property_type_slug" | "updated_at_datetime" | "%future added value";
export type sys_provider_types_constraint = "provider_types_pkey" | "%future added value";
export type sys_provider_types_update_column = "id" | "name" | "%future added value";
export type sys_providers_constraint = "pk_providers" | "providers_org_id_provider_id_key" | "%future added value";
export type sys_providers_properties_constraint = "providers_properties_pkey" | "%future added value";
export type sys_providers_properties_select_column = "created_at_datetime" | "inactivated_at_datetime" | "is_active" | "organization_id" | "property_id" | "provider_id" | "provider_property_id" | "updated_at_datetime" | "%future added value";
export type sys_providers_properties_select_column_sys_providers_properties_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_providers_properties_select_column_sys_providers_properties_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_providers_properties_update_column = "created_at_datetime" | "inactivated_at_datetime" | "organization_id" | "property_id" | "provider_id" | "provider_property_id" | "updated_at_datetime" | "%future added value";
export type sys_providers_select_column = "created_at_datetime" | "ingestion_config" | "is_active" | "organization_id" | "provider_id" | "provider_name" | "provider_type_id" | "related_data_updated_at" | "report_config" | "updated_at_datetime" | "%future added value";
export type sys_providers_select_column_sys_providers_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_providers_select_column_sys_providers_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_providers_update_column = "created_at_datetime" | "ingestion_config" | "is_active" | "organization_id" | "provider_id" | "provider_name" | "provider_type_id" | "related_data_updated_at" | "report_config" | "updated_at_datetime" | "%future added value";
export type sys_reminders_constraint = "reminders_pkey" | "%future added value";
export type sys_reminders_reviewed_constraint = "reminders_reviewed_pkey" | "reminders_reviewed_reminder_id_user_id" | "%future added value";
export type sys_reminders_reviewed_select_column = "organization_id" | "reminder_id" | "reminders_reviewed_id" | "reviewed_at_datetime" | "user_id" | "%future added value";
export type sys_reminders_reviewed_update_column = "organization_id" | "reminder_id" | "reminders_reviewed_id" | "reviewed_at_datetime" | "user_id" | "%future added value";
export type sys_reminders_select_column = "author_id" | "created_at_datetime" | "is_resolved" | "organization_id" | "package_report_id" | "property_batch_id" | "property_id" | "reminder_id" | "submission_id" | "text" | "%future added value";
export type sys_reminders_select_column_sys_reminders_aggregate_bool_exp_bool_and_arguments_columns = "is_resolved" | "%future added value";
export type sys_reminders_select_column_sys_reminders_aggregate_bool_exp_bool_or_arguments_columns = "is_resolved" | "%future added value";
export type sys_reminders_update_column = "author_id" | "created_at_datetime" | "is_resolved" | "organization_id" | "package_report_id" | "property_batch_id" | "property_id" | "reminder_id" | "submission_id" | "text" | "%future added value";
export type sys_reports_constraint = "reports_pkey" | "reports_report_id_organization_id_key" | "reports_report_slug_organization_id_key" | "%future added value";
export type sys_reports_update_column = "organization_id" | "report_config" | "report_id" | "report_slug" | "%future added value";
export type sys_submission_types_constraint = "pk_submission_types" | "submission_types_submission_type_slug_organization_id_key" | "uk_submission_types_submission_type_id_organization_id" | "%future added value";
export type sys_submission_types_select_column = "created_at_datetime" | "feature_flag" | "organization_id" | "submission_type_description" | "submission_type_id" | "submission_type_label" | "submission_type_slug" | "updated_at_datetime" | "%future added value";
export type sys_submission_types_update_column = "created_at_datetime" | "feature_flag" | "organization_id" | "submission_type_description" | "submission_type_id" | "submission_type_label" | "submission_type_slug" | "updated_at_datetime" | "%future added value";
export type sys_submissions_constraint = "pk_submissions" | "%future added value";
export type sys_submissions_select_column = "closed_at_datetime" | "created_at_datetime" | "due_date" | "fiscal_year_flag" | "is_closed" | "organization_id" | "owner_id" | "provider_id" | "reporting_period" | "reporting_period_end_date" | "reporting_period_start_date" | "submission_id" | "submission_name" | "submission_type_id" | "updated_at_datetime" | "workflow_mode_id" | "%future added value";
export type sys_submissions_select_column_sys_submissions_aggregate_bool_exp_bool_and_arguments_columns = "fiscal_year_flag" | "is_closed" | "%future added value";
export type sys_submissions_select_column_sys_submissions_aggregate_bool_exp_bool_or_arguments_columns = "fiscal_year_flag" | "is_closed" | "%future added value";
export type sys_submissions_update_column = "closed_at_datetime" | "created_at_datetime" | "due_date" | "fiscal_year_flag" | "is_closed" | "organization_id" | "owner_id" | "provider_id" | "reporting_period" | "reporting_period_end_date" | "reporting_period_start_date" | "submission_id" | "submission_name" | "submission_type_id" | "updated_at_datetime" | "workflow_mode_id" | "%future added value";
export type sys_users_constraint = "pk_users" | "uk_users_user_id_organization_id" | "%future added value";
export type sys_users_select_column = "created_at_datetime" | "is_active" | "organization_id" | "provider_id" | "updated_at_datetime" | "user_id" | "%future added value";
export type sys_users_select_column_sys_users_aggregate_bool_exp_bool_and_arguments_columns = "is_active" | "%future added value";
export type sys_users_select_column_sys_users_aggregate_bool_exp_bool_or_arguments_columns = "is_active" | "%future added value";
export type sys_users_update_column = "created_at_datetime" | "is_active" | "organization_id" | "provider_id" | "updated_at_datetime" | "user_id" | "%future added value";
export type sys_properties_mapping_sets_insert_input = {
  created_at_datetime?: string | null;
  is_active?: boolean | null;
  mapping_field_id?: number | null;
  mapping_set?: sys_mapping_sets_obj_rel_insert_input | null;
  mapping_set_id?: number | null;
  organization_id?: number | null;
  property?: sys_properties_obj_rel_insert_input | null;
  property_id?: number | null;
  property_mapping_set_id?: number | null;
  provider_id?: number | null;
  updated_at_datetime?: string | null;
};
export type sys_mapping_sets_obj_rel_insert_input = {
  data: sys_mapping_sets_insert_input;
  on_conflict?: sys_mapping_sets_on_conflict | null;
};
export type sys_mapping_sets_insert_input = {
  created_at_datetime?: string | null;
  is_active?: boolean | null;
  mapping_field?: sys_mapping_fields_obj_rel_insert_input | null;
  mapping_field_id?: number | null;
  mapping_set_description?: string | null;
  mapping_set_id?: number | null;
  mapping_set_values?: sys_mapping_set_values_arr_rel_insert_input | null;
  master_chart_of_account_id?: number | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  organization_master_chart_of_accounts?: sys_organization_master_chart_of_accounts_obj_rel_insert_input | null;
  properties_mapping_sets?: sys_properties_mapping_sets_arr_rel_insert_input | null;
  provider?: sys_providers_obj_rel_insert_input | null;
  provider_id?: number | null;
  updated_at_datetime?: string | null;
};
export type sys_mapping_fields_obj_rel_insert_input = {
  data: sys_mapping_fields_insert_input;
  on_conflict?: sys_mapping_fields_on_conflict | null;
};
export type sys_mapping_fields_insert_input = {
  created_at_datetime?: string | null;
  custom_attributes?: any | null;
  is_active?: boolean | null;
  is_single_mapping_set?: boolean | null;
  mapping_field_description?: string | null;
  mapping_field_id?: number | null;
  mapping_field_name?: string | null;
  mapping_sets?: sys_mapping_sets_arr_rel_insert_input | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  updated_at_datetime?: string | null;
};
export type sys_mapping_sets_arr_rel_insert_input = {
  data: ReadonlyArray<sys_mapping_sets_insert_input>;
  on_conflict?: sys_mapping_sets_on_conflict | null;
};
export type sys_mapping_sets_on_conflict = {
  constraint: sys_mapping_sets_constraint;
  update_columns: ReadonlyArray<sys_mapping_sets_update_column>;
  where?: sys_mapping_sets_bool_exp | null;
};
export type sys_mapping_sets_bool_exp = {
  _and?: ReadonlyArray<sys_mapping_sets_bool_exp> | null;
  _not?: sys_mapping_sets_bool_exp | null;
  _or?: ReadonlyArray<sys_mapping_sets_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  mapping_field?: sys_mapping_fields_bool_exp | null;
  mapping_field_id?: Int_comparison_exp | null;
  mapping_set_description?: String_comparison_exp | null;
  mapping_set_id?: Int_comparison_exp | null;
  mapping_set_values?: sys_mapping_set_values_bool_exp | null;
  mapping_set_values_aggregate?: sys_mapping_set_values_aggregate_bool_exp | null;
  master_chart_of_account_id?: Int_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  organization_master_chart_of_accounts?: sys_organization_master_chart_of_accounts_bool_exp | null;
  properties_mapping_sets?: sys_properties_mapping_sets_bool_exp | null;
  properties_mapping_sets_aggregate?: sys_properties_mapping_sets_aggregate_bool_exp | null;
  provider?: sys_providers_bool_exp | null;
  provider_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type timestamptz_comparison_exp = {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _in?: ReadonlyArray<string> | null;
  _is_null?: boolean | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nin?: ReadonlyArray<string> | null;
};
export type Boolean_comparison_exp = {
  _eq?: boolean | null;
  _gt?: boolean | null;
  _gte?: boolean | null;
  _in?: ReadonlyArray<boolean> | null;
  _is_null?: boolean | null;
  _lt?: boolean | null;
  _lte?: boolean | null;
  _neq?: boolean | null;
  _nin?: ReadonlyArray<boolean> | null;
};
export type sys_mapping_fields_bool_exp = {
  _and?: ReadonlyArray<sys_mapping_fields_bool_exp> | null;
  _not?: sys_mapping_fields_bool_exp | null;
  _or?: ReadonlyArray<sys_mapping_fields_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  custom_attributes?: jsonb_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  is_single_mapping_set?: Boolean_comparison_exp | null;
  mapping_field_description?: String_comparison_exp | null;
  mapping_field_id?: Int_comparison_exp | null;
  mapping_field_name?: String_comparison_exp | null;
  mapping_sets?: sys_mapping_sets_bool_exp | null;
  mapping_sets_aggregate?: sys_mapping_sets_aggregate_bool_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type jsonb_comparison_exp = {
  _cast?: jsonb_cast_exp | null;
  _contained_in?: any | null;
  _contains?: any | null;
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _has_key?: string | null;
  _has_keys_all?: ReadonlyArray<string> | null;
  _has_keys_any?: ReadonlyArray<string> | null;
  _in?: ReadonlyArray<any> | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: ReadonlyArray<any> | null;
};
export type jsonb_cast_exp = {
  String?: String_comparison_exp | null;
};
export type String_comparison_exp = {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _ilike?: string | null;
  _in?: ReadonlyArray<string> | null;
  _iregex?: string | null;
  _is_null?: boolean | null;
  _like?: string | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nilike?: string | null;
  _nin?: ReadonlyArray<string> | null;
  _niregex?: string | null;
  _nlike?: string | null;
  _nregex?: string | null;
  _nsimilar?: string | null;
  _regex?: string | null;
  _similar?: string | null;
};
export type Int_comparison_exp = {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: ReadonlyArray<number> | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: ReadonlyArray<number> | null;
};
export type sys_mapping_sets_aggregate_bool_exp = {
  bool_and?: sys_mapping_sets_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_mapping_sets_aggregate_bool_exp_bool_or | null;
  count?: sys_mapping_sets_aggregate_bool_exp_count | null;
};
export type sys_mapping_sets_aggregate_bool_exp_bool_and = {
  arguments: sys_mapping_sets_select_column_sys_mapping_sets_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_mapping_sets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_mapping_sets_aggregate_bool_exp_bool_or = {
  arguments: sys_mapping_sets_select_column_sys_mapping_sets_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_mapping_sets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_mapping_sets_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_mapping_sets_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_mapping_sets_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_organizations_bool_exp = {
  _and?: ReadonlyArray<sys_organizations_bool_exp> | null;
  _not?: sys_organizations_bool_exp | null;
  _or?: ReadonlyArray<sys_organizations_bool_exp> | null;
  batch_files?: sys_batch_files_bool_exp | null;
  batch_files_aggregate?: sys_batch_files_aggregate_bool_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  datasets?: sys_datasets_bool_exp | null;
  datasets_aggregate?: sys_datasets_aggregate_bool_exp | null;
  flatfile_team_name?: String_comparison_exp | null;
  mapping_fields?: sys_mapping_fields_bool_exp | null;
  mapping_fields_aggregate?: sys_mapping_fields_aggregate_bool_exp | null;
  mapping_sets?: sys_mapping_sets_bool_exp | null;
  mapping_sets_aggregate?: sys_mapping_sets_aggregate_bool_exp | null;
  organization_account_tree_details?: sys_organization_account_tree_details_bool_exp | null;
  organization_account_tree_details_aggregate?: sys_organization_account_tree_details_aggregate_bool_exp | null;
  organization_description?: String_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  organization_label?: String_comparison_exp | null;
  organization_slug?: String_comparison_exp | null;
  properties_datasets?: sys_properties_datasets_bool_exp | null;
  properties_datasets_aggregate?: sys_properties_datasets_aggregate_bool_exp | null;
  properties_roles_users?: sys_properties_roles_users_bool_exp | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_bool_exp | null;
  property_batch_datasets?: sys_property_batch_datasets_bool_exp | null;
  property_batch_datasets_aggregate?: sys_property_batch_datasets_aggregate_bool_exp | null;
  property_batch_stage_transitions?: sys_property_batch_stage_transitions_bool_exp | null;
  property_batch_stage_transitions_aggregate?: sys_property_batch_stage_transitions_aggregate_bool_exp | null;
  property_batches?: sys_property_batches_bool_exp | null;
  property_batches_aggregate?: sys_property_batches_aggregate_bool_exp | null;
  property_model?: sys_property_models_bool_exp | null;
  property_model_id?: Int_comparison_exp | null;
  providers?: sys_providers_bool_exp | null;
  providers_aggregate?: sys_providers_aggregate_bool_exp | null;
  sakura_organization?: sakura_organization_bool_exp | null;
  sakura_users?: sakura_user_bool_exp | null;
  sakura_users_aggregate?: sakura_user_aggregate_bool_exp | null;
  settings?: jsonb_comparison_exp | null;
  submission_types?: sys_submission_types_bool_exp | null;
  submission_types_aggregate?: sys_submission_types_aggregate_bool_exp | null;
  submissions?: sys_submissions_bool_exp | null;
  submissions_aggregate?: sys_submissions_aggregate_bool_exp | null;
  updated_at_datetime?: timestamp_comparison_exp | null;
};
export type sys_batch_files_bool_exp = {
  _and?: ReadonlyArray<sys_batch_files_bool_exp> | null;
  _not?: sys_batch_files_bool_exp | null;
  _or?: ReadonlyArray<sys_batch_files_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  dataset?: sys_datasets_bool_exp | null;
  dataset_id?: Int_comparison_exp | null;
  file_id?: Int_comparison_exp | null;
  file_name?: String_comparison_exp | null;
  file_reference_souce?: String_comparison_exp | null;
  file_source?: String_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  processed_row_count?: Int_comparison_exp | null;
  property_batch_datasets?: sys_property_batch_datasets_bool_exp | null;
  property_batch_datasets_aggregate?: sys_property_batch_datasets_aggregate_bool_exp | null;
  property_batch_datasets_by_dataset_id_file_id?: sys_property_batch_datasets_bool_exp | null;
  property_batch_datasets_by_dataset_id_file_id_aggregate?: sys_property_batch_datasets_aggregate_bool_exp | null;
  row_count?: Int_comparison_exp | null;
  submitter?: sys_users_bool_exp | null;
  submitter_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_datasets_bool_exp = {
  _and?: ReadonlyArray<sys_datasets_bool_exp> | null;
  _not?: sys_datasets_bool_exp | null;
  _or?: ReadonlyArray<sys_datasets_bool_exp> | null;
  batch_files?: sys_batch_files_bool_exp | null;
  batch_files_aggregate?: sys_batch_files_aggregate_bool_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  dataset_description?: String_comparison_exp | null;
  dataset_id?: Int_comparison_exp | null;
  dataset_label?: String_comparison_exp | null;
  dataset_object?: jsonb_comparison_exp | null;
  dataset_slug?: String_comparison_exp | null;
  dataset_validation_rules?: sys_dataset_validation_rules_bool_exp | null;
  dataset_validation_rules_aggregate?: sys_dataset_validation_rules_aggregate_bool_exp | null;
  feature_flag?: String_comparison_exp | null;
  flatfile_embed_id?: String_comparison_exp | null;
  flatfile_private_key?: String_comparison_exp | null;
  is_tsa_enabled?: Boolean_comparison_exp | null;
  managed_by?: String_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  package_reports_datasets?: sys_package_reports_datasets_bool_exp | null;
  package_reports_datasets_aggregate?: sys_package_reports_datasets_aggregate_bool_exp | null;
  properties_datasets?: sys_properties_datasets_bool_exp | null;
  properties_datasets_aggregate?: sys_properties_datasets_aggregate_bool_exp | null;
  property_batch_datasets?: sys_property_batch_datasets_bool_exp | null;
  property_batch_datasets_aggregate?: sys_property_batch_datasets_aggregate_bool_exp | null;
  submission_type?: sys_submission_types_bool_exp | null;
  submission_type_id?: Int_comparison_exp | null;
  tsa_flatfile_embed_id?: String_comparison_exp | null;
  tsa_flatfile_private_key?: String_comparison_exp | null;
  unified_ingest_schemas?: jsonb_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_batch_files_aggregate_bool_exp = {
  count?: sys_batch_files_aggregate_bool_exp_count | null;
};
export type sys_batch_files_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_batch_files_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_batch_files_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_dataset_validation_rules_bool_exp = {
  _and?: ReadonlyArray<sys_dataset_validation_rules_bool_exp> | null;
  _not?: sys_dataset_validation_rules_bool_exp | null;
  _or?: ReadonlyArray<sys_dataset_validation_rules_bool_exp> | null;
  banner_config?: jsonb_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  dataset?: sys_datasets_bool_exp | null;
  dataset_id?: Int_comparison_exp | null;
  dataset_validation_rule_id?: Int_comparison_exp | null;
  feature_flag?: String_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  properties_dataset_validation_rules?: sys_properties_dataset_validation_rules_bool_exp | null;
  properties_dataset_validation_rules_aggregate?: sys_properties_dataset_validation_rules_aggregate_bool_exp | null;
  property_batch_dataset_validations?: sys_property_batch_dataset_validations_bool_exp | null;
  property_batch_dataset_validations_aggregate?: sys_property_batch_dataset_validations_aggregate_bool_exp | null;
  report?: sys_reports_bool_exp | null;
  report_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  validation_description?: String_comparison_exp | null;
  validation_label?: String_comparison_exp | null;
  validation_params?: jsonb_comparison_exp | null;
  validation_slug?: String_comparison_exp | null;
  validation_type?: String_comparison_exp | null;
};
export type sys_properties_dataset_validation_rules_bool_exp = {
  _and?: ReadonlyArray<sys_properties_dataset_validation_rules_bool_exp> | null;
  _not?: sys_properties_dataset_validation_rules_bool_exp | null;
  _or?: ReadonlyArray<sys_properties_dataset_validation_rules_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  dataset_validation_rule?: sys_dataset_validation_rules_bool_exp | null;
  dataset_validation_rule_id?: Int_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  property?: sys_properties_bool_exp | null;
  property_dataset_validation_rule_id?: Int_comparison_exp | null;
  property_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  validation_mode?: String_comparison_exp | null;
};
export type sys_properties_bool_exp = {
  _and?: ReadonlyArray<sys_properties_bool_exp> | null;
  _not?: sys_properties_bool_exp | null;
  _or?: ReadonlyArray<sys_properties_bool_exp> | null;
  address?: String_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  custom_attributes?: jsonb_comparison_exp | null;
  entity_id?: String_comparison_exp | null;
  entity_name?: String_comparison_exp | null;
  from_property_relationships?: sys_property_relationships_bool_exp | null;
  from_property_relationships_aggregate?: sys_property_relationships_aggregate_bool_exp | null;
  fund?: String_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  is_sold?: Boolean_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  properties_batch_validation_rules?: sys_properties_batch_validation_rules_bool_exp | null;
  properties_batch_validation_rules_aggregate?: sys_properties_batch_validation_rules_aggregate_bool_exp | null;
  properties_dataset_validation_rules?: sys_properties_dataset_validation_rules_bool_exp | null;
  properties_dataset_validation_rules_aggregate?: sys_properties_dataset_validation_rules_aggregate_bool_exp | null;
  properties_datasets?: sys_properties_datasets_bool_exp | null;
  properties_datasets_aggregate?: sys_properties_datasets_aggregate_bool_exp | null;
  properties_mapping_sets?: sys_properties_mapping_sets_bool_exp | null;
  properties_mapping_sets_aggregate?: sys_properties_mapping_sets_aggregate_bool_exp | null;
  properties_roles_users?: sys_properties_roles_users_bool_exp | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_bool_exp | null;
  property_batches?: sys_property_batches_bool_exp | null;
  property_batches_aggregate?: sys_property_batches_aggregate_bool_exp | null;
  property_code?: String_comparison_exp | null;
  property_id?: Int_comparison_exp | null;
  property_name?: String_comparison_exp | null;
  property_type?: sys_property_types_bool_exp | null;
  property_type_id?: Int_comparison_exp | null;
  provider?: sys_providers_bool_exp | null;
  provider_id?: Int_comparison_exp | null;
  providers_properties?: sys_providers_properties_bool_exp | null;
  providers_properties_aggregate?: sys_providers_properties_aggregate_bool_exp | null;
  reminders?: sys_reminders_bool_exp | null;
  reminders_aggregate?: sys_reminders_aggregate_bool_exp | null;
  sold_date?: date_comparison_exp | null;
  to_property_relationships?: sys_property_relationships_bool_exp | null;
  to_property_relationships_aggregate?: sys_property_relationships_aggregate_bool_exp | null;
  type?: String_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_property_relationships_bool_exp = {
  _and?: ReadonlyArray<sys_property_relationships_bool_exp> | null;
  _not?: sys_property_relationships_bool_exp | null;
  _or?: ReadonlyArray<sys_property_relationships_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  from_property?: sys_properties_bool_exp | null;
  inactivated_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  properties_flattened_union?: sys_properties_flattened_union_bool_exp | null;
  property_id_from?: Int_comparison_exp | null;
  property_id_to?: Int_comparison_exp | null;
  property_relationship_id?: Int_comparison_exp | null;
  to_property?: sys_properties_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_properties_flattened_union_bool_exp = {
  _and?: ReadonlyArray<sys_properties_flattened_union_bool_exp> | null;
  _not?: sys_properties_flattened_union_bool_exp | null;
  _or?: ReadonlyArray<sys_properties_flattened_union_bool_exp> | null;
  address?: String_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  custom_attributes?: jsonb_comparison_exp | null;
  datasets_count?: numeric_comparison_exp | null;
  datasets_property_id?: Int_comparison_exp | null;
  entity_id?: String_comparison_exp | null;
  entity_name?: String_comparison_exp | null;
  fund?: String_comparison_exp | null;
  investment_properties_dataset_validation_rules?: sys_properties_dataset_validation_rules_bool_exp | null;
  investment_properties_dataset_validation_rules_aggregate?: sys_properties_dataset_validation_rules_aggregate_bool_exp | null;
  is_active?: Boolean_comparison_exp | null;
  is_ready_for_submission?: Boolean_comparison_exp | null;
  is_sold?: Boolean_comparison_exp | null;
  mappings_count?: numeric_comparison_exp | null;
  mappings_property_id?: Int_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  parent_property?: sys_properties_bool_exp | null;
  parent_property_id?: Int_comparison_exp | null;
  parent_property_type_id?: Int_comparison_exp | null;
  properties_batch_validation_rules?: sys_properties_batch_validation_rules_bool_exp | null;
  properties_batch_validation_rules_aggregate?: sys_properties_batch_validation_rules_aggregate_bool_exp | null;
  properties_dataset_validation_rules?: sys_properties_dataset_validation_rules_bool_exp | null;
  properties_dataset_validation_rules_aggregate?: sys_properties_dataset_validation_rules_aggregate_bool_exp | null;
  properties_datasets?: sys_properties_datasets_bool_exp | null;
  properties_datasets_aggregate?: sys_properties_datasets_aggregate_bool_exp | null;
  properties_mapping_sets?: sys_properties_mapping_sets_bool_exp | null;
  properties_mapping_sets_aggregate?: sys_properties_mapping_sets_aggregate_bool_exp | null;
  properties_roles_users?: sys_properties_roles_users_bool_exp | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_bool_exp | null;
  property_batches?: sys_property_batches_bool_exp | null;
  property_batches_aggregate?: sys_property_batches_aggregate_bool_exp | null;
  property_code?: String_comparison_exp | null;
  property_id?: Int_comparison_exp | null;
  property_mapping?: String_comparison_exp | null;
  property_model_id?: Int_comparison_exp | null;
  property_models?: sys_property_models_bool_exp | null;
  property_name?: String_comparison_exp | null;
  property_type_id?: Int_comparison_exp | null;
  provider?: sys_providers_bool_exp | null;
  provider_id?: Int_comparison_exp | null;
  sold_date?: date_comparison_exp | null;
  type?: String_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  users_count?: numeric_comparison_exp | null;
  users_property_id?: Int_comparison_exp | null;
};
export type numeric_comparison_exp = {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: ReadonlyArray<number> | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: ReadonlyArray<number> | null;
};
export type sys_properties_dataset_validation_rules_aggregate_bool_exp = {
  bool_and?: sys_properties_dataset_validation_rules_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_properties_dataset_validation_rules_aggregate_bool_exp_bool_or | null;
  count?: sys_properties_dataset_validation_rules_aggregate_bool_exp_count | null;
};
export type sys_properties_dataset_validation_rules_aggregate_bool_exp_bool_and = {
  arguments: sys_properties_dataset_validation_rules_select_column_sys_properties_dataset_validation_rules_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_dataset_validation_rules_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_dataset_validation_rules_aggregate_bool_exp_bool_or = {
  arguments: sys_properties_dataset_validation_rules_select_column_sys_properties_dataset_validation_rules_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_dataset_validation_rules_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_dataset_validation_rules_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_properties_dataset_validation_rules_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_properties_dataset_validation_rules_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_properties_batch_validation_rules_bool_exp = {
  _and?: ReadonlyArray<sys_properties_batch_validation_rules_bool_exp> | null;
  _not?: sys_properties_batch_validation_rules_bool_exp | null;
  _or?: ReadonlyArray<sys_properties_batch_validation_rules_bool_exp> | null;
  batch_validation_rule?: sys_batch_validation_rules_bool_exp | null;
  batch_validation_rule_id?: Int_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  property?: sys_properties_bool_exp | null;
  property_batch_validation_rule_id?: Int_comparison_exp | null;
  property_batch_validations?: sys_property_batch_validations_bool_exp | null;
  property_batch_validations_aggregate?: sys_property_batch_validations_aggregate_bool_exp | null;
  property_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  validation_mode?: String_comparison_exp | null;
};
export type sys_batch_validation_rules_bool_exp = {
  _and?: ReadonlyArray<sys_batch_validation_rules_bool_exp> | null;
  _not?: sys_batch_validation_rules_bool_exp | null;
  _or?: ReadonlyArray<sys_batch_validation_rules_bool_exp> | null;
  banner_config?: jsonb_comparison_exp | null;
  batch_validation_rule_id?: Int_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  datasets_batch_validation_rules?: sys_datasets_batch_validation_rules_bool_exp | null;
  datasets_batch_validation_rules_aggregate?: sys_datasets_batch_validation_rules_aggregate_bool_exp | null;
  feature_flag?: String_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  organization_params?: jsonb_comparison_exp | null;
  properties_batch_validation_rules?: sys_properties_batch_validation_rules_bool_exp | null;
  properties_batch_validation_rules_aggregate?: sys_properties_batch_validation_rules_aggregate_bool_exp | null;
  report?: sys_reports_bool_exp | null;
  report_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  validation_description?: String_comparison_exp | null;
  validation_label?: String_comparison_exp | null;
  validation_params?: jsonb_comparison_exp | null;
  validation_slug?: String_comparison_exp | null;
  validation_type?: String_comparison_exp | null;
};
export type sys_datasets_batch_validation_rules_bool_exp = {
  _and?: ReadonlyArray<sys_datasets_batch_validation_rules_bool_exp> | null;
  _not?: sys_datasets_batch_validation_rules_bool_exp | null;
  _or?: ReadonlyArray<sys_datasets_batch_validation_rules_bool_exp> | null;
  batch_validation_rule_id?: Int_comparison_exp | null;
  dataset?: sys_datasets_bool_exp | null;
  dataset_batch_validation_rule_id?: Int_comparison_exp | null;
  dataset_id?: Int_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
};
export type sys_datasets_batch_validation_rules_aggregate_bool_exp = {
  bool_and?: sys_datasets_batch_validation_rules_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_datasets_batch_validation_rules_aggregate_bool_exp_bool_or | null;
  count?: sys_datasets_batch_validation_rules_aggregate_bool_exp_count | null;
};
export type sys_datasets_batch_validation_rules_aggregate_bool_exp_bool_and = {
  arguments: sys_datasets_batch_validation_rules_select_column_sys_datasets_batch_validation_rules_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_datasets_batch_validation_rules_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_datasets_batch_validation_rules_aggregate_bool_exp_bool_or = {
  arguments: sys_datasets_batch_validation_rules_select_column_sys_datasets_batch_validation_rules_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_datasets_batch_validation_rules_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_datasets_batch_validation_rules_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_datasets_batch_validation_rules_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_datasets_batch_validation_rules_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_properties_batch_validation_rules_aggregate_bool_exp = {
  bool_and?: sys_properties_batch_validation_rules_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_properties_batch_validation_rules_aggregate_bool_exp_bool_or | null;
  count?: sys_properties_batch_validation_rules_aggregate_bool_exp_count | null;
};
export type sys_properties_batch_validation_rules_aggregate_bool_exp_bool_and = {
  arguments: sys_properties_batch_validation_rules_select_column_sys_properties_batch_validation_rules_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_batch_validation_rules_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_batch_validation_rules_aggregate_bool_exp_bool_or = {
  arguments: sys_properties_batch_validation_rules_select_column_sys_properties_batch_validation_rules_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_batch_validation_rules_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_batch_validation_rules_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_properties_batch_validation_rules_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_properties_batch_validation_rules_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_reports_bool_exp = {
  _and?: ReadonlyArray<sys_reports_bool_exp> | null;
  _not?: sys_reports_bool_exp | null;
  _or?: ReadonlyArray<sys_reports_bool_exp> | null;
  organization_id?: Int_comparison_exp | null;
  report_config?: jsonb_comparison_exp | null;
  report_id?: Int_comparison_exp | null;
  report_slug?: String_comparison_exp | null;
};
export type sys_property_batch_validations_bool_exp = {
  _and?: ReadonlyArray<sys_property_batch_validations_bool_exp> | null;
  _not?: sys_property_batch_validations_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batch_validations_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_valid?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  properties_batch_validation_rule?: sys_properties_batch_validation_rules_bool_exp | null;
  property_batch?: sys_property_batches_bool_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  property_batch_validation_id?: Int_comparison_exp | null;
  property_batch_validation_rule_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  validation_mode?: String_comparison_exp | null;
  validation_params?: jsonb_comparison_exp | null;
};
export type sys_property_batches_bool_exp = {
  _and?: ReadonlyArray<sys_property_batches_bool_exp> | null;
  _not?: sys_property_batches_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batches_bool_exp> | null;
  approved_at_datetime?: timestamptz_comparison_exp | null;
  check_regular_user_permission?: Boolean_comparison_exp | null;
  comments?: sys_comments_bool_exp | null;
  comments_aggregate?: sys_comments_aggregate_bool_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  cycle_time?: numeric_comparison_exp | null;
  investment_is_ready_for_bulk_approve?: Boolean_comparison_exp | null;
  investment_is_ready_for_bulk_submit?: Boolean_comparison_exp | null;
  investment_is_ready_for_submit?: Boolean_comparison_exp | null;
  is_all_children_in_allowed_stage_for_user?: Boolean_comparison_exp | null;
  is_all_children_ready_for_submit?: Boolean_comparison_exp | null;
  is_approved?: Boolean_comparison_exp | null;
  is_in_last_review_stage?: Boolean_comparison_exp | null;
  is_ready_for_bulk_submit?: Boolean_comparison_exp | null;
  is_ready_for_reject?: Boolean_comparison_exp | null;
  is_ready_for_submit?: Boolean_comparison_exp | null;
  is_valid?: Boolean_comparison_exp | null;
  kpi_datasets_total_count?: Int_comparison_exp | null;
  kpi_datasets_uploaded_count?: Int_comparison_exp | null;
  kpi_package_reports_reviewed_count?: Int_comparison_exp | null;
  kpi_package_reports_total_count?: Int_comparison_exp | null;
  kpi_properties_approved_count?: Int_comparison_exp | null;
  kpi_properties_approved_or_rejected_count?: Int_comparison_exp | null;
  kpi_properties_submitted_count?: Int_comparison_exp | null;
  kpi_validations_failed_count?: Int_comparison_exp | null;
  kpi_validations_warn_count?: Int_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  package_reports?: sys_package_reports_bool_exp | null;
  properties_flattened_union?: sys_properties_flattened_union_bool_exp | null;
  property?: sys_properties_bool_exp | null;
  property_batch_datasets?: sys_property_batch_datasets_bool_exp | null;
  property_batch_datasets_aggregate?: sys_property_batch_datasets_aggregate_bool_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  property_batch_package_reports?: sys_property_batch_package_reports_bool_exp | null;
  property_batch_package_reports_aggregate?: sys_property_batch_package_reports_aggregate_bool_exp | null;
  property_batch_stage?: sys_property_batch_stages_bool_exp | null;
  property_batch_stage_id?: Int_comparison_exp | null;
  property_batch_stage_transitions?: sys_property_batch_stage_transitions_bool_exp | null;
  property_batch_stage_transitions_aggregate?: sys_property_batch_stage_transitions_aggregate_bool_exp | null;
  property_batch_validations?: sys_property_batch_validations_bool_exp | null;
  property_batch_validations_aggregate?: sys_property_batch_validations_aggregate_bool_exp | null;
  property_batches_monitoring?: sys_property_batches_monitoring_bool_exp | null;
  property_id?: Int_comparison_exp | null;
  reminders?: sys_reminders_bool_exp | null;
  reminders_aggregate?: sys_reminders_aggregate_bool_exp | null;
  role_status?: String_comparison_exp | null;
  submission?: sys_submissions_bool_exp | null;
  submission_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  validated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_comments_bool_exp = {
  _and?: ReadonlyArray<sys_comments_bool_exp> | null;
  _not?: sys_comments_bool_exp | null;
  _or?: ReadonlyArray<sys_comments_bool_exp> | null;
  author_id?: Int_comparison_exp | null;
  comment_id?: Int_comparison_exp | null;
  comment_type?: sys_comment_types_bool_exp | null;
  comment_type_id?: Int_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  package_report?: sys_package_reports_bool_exp | null;
  package_report_id?: Int_comparison_exp | null;
  property_batch?: sys_property_batches_bool_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  text?: String_comparison_exp | null;
  user?: sys_users_bool_exp | null;
};
export type sys_comment_types_bool_exp = {
  _and?: ReadonlyArray<sys_comment_types_bool_exp> | null;
  _not?: sys_comment_types_bool_exp | null;
  _or?: ReadonlyArray<sys_comment_types_bool_exp> | null;
  comment_type_id?: Int_comparison_exp | null;
  comment_type_slug?: String_comparison_exp | null;
};
export type sys_package_reports_bool_exp = {
  _and?: ReadonlyArray<sys_package_reports_bool_exp> | null;
  _not?: sys_package_reports_bool_exp | null;
  _or?: ReadonlyArray<sys_package_reports_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  organization_params?: jsonb_comparison_exp | null;
  package_report_description?: String_comparison_exp | null;
  package_report_id?: Int_comparison_exp | null;
  package_report_label?: String_comparison_exp | null;
  package_report_slug?: String_comparison_exp | null;
  package_reports_datasets?: sys_package_reports_datasets_bool_exp | null;
  package_reports_datasets_aggregate?: sys_package_reports_datasets_aggregate_bool_exp | null;
  property_batch_package_reports?: sys_property_batch_package_reports_bool_exp | null;
  property_batch_package_reports_aggregate?: sys_property_batch_package_reports_aggregate_bool_exp | null;
  report?: sys_reports_bool_exp | null;
  report_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_package_reports_datasets_bool_exp = {
  _and?: ReadonlyArray<sys_package_reports_datasets_bool_exp> | null;
  _not?: sys_package_reports_datasets_bool_exp | null;
  _or?: ReadonlyArray<sys_package_reports_datasets_bool_exp> | null;
  dataset?: sys_datasets_bool_exp | null;
  dataset_id?: Int_comparison_exp | null;
  package_report?: sys_package_reports_bool_exp | null;
  package_report_dataset_id?: Int_comparison_exp | null;
  package_report_id?: Int_comparison_exp | null;
};
export type sys_package_reports_datasets_aggregate_bool_exp = {
  count?: sys_package_reports_datasets_aggregate_bool_exp_count | null;
};
export type sys_package_reports_datasets_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_package_reports_datasets_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_package_reports_datasets_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_batch_package_reports_bool_exp = {
  _and?: ReadonlyArray<sys_property_batch_package_reports_bool_exp> | null;
  _not?: sys_property_batch_package_reports_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batch_package_reports_bool_exp> | null;
  is_reviewed?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  package_report?: sys_package_reports_bool_exp | null;
  package_report_id?: Int_comparison_exp | null;
  property_batch?: sys_property_batches_bool_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  property_batch_package_report_id?: Int_comparison_exp | null;
  property_batch_stage?: sys_property_batch_stages_bool_exp | null;
  property_batch_stage_id?: Int_comparison_exp | null;
  reviewed_datetime?: timestamptz_comparison_exp | null;
  user_id?: Int_comparison_exp | null;
};
export type sys_property_batch_stages_bool_exp = {
  _and?: ReadonlyArray<sys_property_batch_stages_bool_exp> | null;
  _not?: sys_property_batch_stages_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batch_stages_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  property_batch_package_reports?: sys_property_batch_package_reports_bool_exp | null;
  property_batch_package_reports_aggregate?: sys_property_batch_package_reports_aggregate_bool_exp | null;
  property_batch_stage_description?: String_comparison_exp | null;
  property_batch_stage_id?: Int_comparison_exp | null;
  property_batch_stage_transitions?: sys_property_batch_stage_transitions_bool_exp | null;
  property_batch_stage_transitions_aggregate?: sys_property_batch_stage_transitions_aggregate_bool_exp | null;
  property_batches?: sys_property_batches_bool_exp | null;
  property_batches_aggregate?: sys_property_batches_aggregate_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_property_batch_package_reports_aggregate_bool_exp = {
  bool_and?: sys_property_batch_package_reports_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_property_batch_package_reports_aggregate_bool_exp_bool_or | null;
  count?: sys_property_batch_package_reports_aggregate_bool_exp_count | null;
};
export type sys_property_batch_package_reports_aggregate_bool_exp_bool_and = {
  arguments: sys_property_batch_package_reports_select_column_sys_property_batch_package_reports_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batch_package_reports_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batch_package_reports_aggregate_bool_exp_bool_or = {
  arguments: sys_property_batch_package_reports_select_column_sys_property_batch_package_reports_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batch_package_reports_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batch_package_reports_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_batch_package_reports_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_batch_package_reports_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_batch_stage_transitions_bool_exp = {
  _and?: ReadonlyArray<sys_property_batch_stage_transitions_bool_exp> | null;
  _not?: sys_property_batch_stage_transitions_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batch_stage_transitions_bool_exp> | null;
  action?: String_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  property_batch?: sys_property_batches_bool_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  property_batch_stage?: sys_property_batch_stages_bool_exp | null;
  property_batch_stage_id?: Int_comparison_exp | null;
  property_batch_stage_transition_id?: Int_comparison_exp | null;
  transition_datetime?: timestamptz_comparison_exp | null;
  user?: sys_users_bool_exp | null;
  user_id?: Int_comparison_exp | null;
};
export type sys_users_bool_exp = {
  _and?: ReadonlyArray<sys_users_bool_exp> | null;
  _not?: sys_users_bool_exp | null;
  _or?: ReadonlyArray<sys_users_bool_exp> | null;
  batch_files?: sys_batch_files_bool_exp | null;
  batch_files_aggregate?: sys_batch_files_aggregate_bool_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  notification_recipients?: sys_notification_recipients_bool_exp | null;
  notification_recipients_aggregate?: sys_notification_recipients_aggregate_bool_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  properties_roles_users?: sys_properties_roles_users_bool_exp | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_bool_exp | null;
  property_batch_stage_transitions?: sys_property_batch_stage_transitions_bool_exp | null;
  property_batch_stage_transitions_aggregate?: sys_property_batch_stage_transitions_aggregate_bool_exp | null;
  provider?: sys_providers_bool_exp | null;
  provider_id?: Int_comparison_exp | null;
  sakura_user?: sakura_user_bool_exp | null;
  submissions?: sys_submissions_bool_exp | null;
  submissions_aggregate?: sys_submissions_aggregate_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  user?: sakura_user_bool_exp | null;
  user_id?: Int_comparison_exp | null;
  users_last_seen?: sakura_users_last_seen_bool_exp | null;
};
export type sys_notification_recipients_bool_exp = {
  _and?: ReadonlyArray<sys_notification_recipients_bool_exp> | null;
  _not?: sys_notification_recipients_bool_exp | null;
  _or?: ReadonlyArray<sys_notification_recipients_bool_exp> | null;
  failed_count?: Int_comparison_exp | null;
  last_failed_attempt_at_datetime?: timestamptz_comparison_exp | null;
  notification?: sys_notifications_bool_exp | null;
  notification_id?: Int_comparison_exp | null;
  sent_at_datetime?: timestamptz_comparison_exp | null;
  user_id?: Int_comparison_exp | null;
};
export type sys_notifications_bool_exp = {
  _and?: ReadonlyArray<sys_notifications_bool_exp> | null;
  _not?: sys_notifications_bool_exp | null;
  _or?: ReadonlyArray<sys_notifications_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  notification_detail_data?: jsonb_comparison_exp | null;
  notification_group_data?: jsonb_comparison_exp | null;
  notification_id?: Int_comparison_exp | null;
  notification_recipients?: sys_notification_recipients_bool_exp | null;
  notification_recipients_aggregate?: sys_notification_recipients_aggregate_bool_exp | null;
  notification_source_data?: jsonb_comparison_exp | null;
  notification_type?: sys_notification_types_bool_exp | null;
  notification_type_id?: Int_comparison_exp | null;
};
export type sys_notification_recipients_aggregate_bool_exp = {
  count?: sys_notification_recipients_aggregate_bool_exp_count | null;
};
export type sys_notification_recipients_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_notification_recipients_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_notification_recipients_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_notification_types_bool_exp = {
  _and?: ReadonlyArray<sys_notification_types_bool_exp> | null;
  _not?: sys_notification_types_bool_exp | null;
  _or?: ReadonlyArray<sys_notification_types_bool_exp> | null;
  notification_type_id?: Int_comparison_exp | null;
  notification_type_slug?: String_comparison_exp | null;
  notifications?: sys_notifications_bool_exp | null;
  notifications_aggregate?: sys_notifications_aggregate_bool_exp | null;
  priority?: Int_comparison_exp | null;
};
export type sys_notifications_aggregate_bool_exp = {
  count?: sys_notifications_aggregate_bool_exp_count | null;
};
export type sys_notifications_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_notifications_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_notifications_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_properties_roles_users_bool_exp = {
  _and?: ReadonlyArray<sys_properties_roles_users_bool_exp> | null;
  _not?: sys_properties_roles_users_bool_exp | null;
  _or?: ReadonlyArray<sys_properties_roles_users_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  property?: sys_properties_bool_exp | null;
  property_id?: Int_comparison_exp | null;
  property_role?: sys_property_roles_bool_exp | null;
  property_role_id?: Int_comparison_exp | null;
  property_role_user_id?: Int_comparison_exp | null;
  provider_id?: Int_comparison_exp | null;
  submission_type?: sys_submission_types_bool_exp | null;
  submission_type_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  user?: sys_users_bool_exp | null;
  user_id?: Int_comparison_exp | null;
};
export type sys_property_roles_bool_exp = {
  _and?: ReadonlyArray<sys_property_roles_bool_exp> | null;
  _not?: sys_property_roles_bool_exp | null;
  _or?: ReadonlyArray<sys_property_roles_bool_exp> | null;
  properties_roles_users?: sys_properties_roles_users_bool_exp | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_bool_exp | null;
  property_batch_role_status?: sys_property_batch_role_statuses_bool_exp | null;
  property_batch_role_status_aggregate?: sys_property_batch_role_statuses_aggregate_bool_exp | null;
  property_role_id?: Int_comparison_exp | null;
  property_role_label?: String_comparison_exp | null;
  property_role_set?: String_comparison_exp | null;
  property_role_slug?: String_comparison_exp | null;
};
export type sys_properties_roles_users_aggregate_bool_exp = {
  bool_and?: sys_properties_roles_users_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_properties_roles_users_aggregate_bool_exp_bool_or | null;
  count?: sys_properties_roles_users_aggregate_bool_exp_count | null;
};
export type sys_properties_roles_users_aggregate_bool_exp_bool_and = {
  arguments: sys_properties_roles_users_select_column_sys_properties_roles_users_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_roles_users_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_roles_users_aggregate_bool_exp_bool_or = {
  arguments: sys_properties_roles_users_select_column_sys_properties_roles_users_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_roles_users_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_roles_users_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_properties_roles_users_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_properties_roles_users_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_batch_role_statuses_bool_exp = {
  _and?: ReadonlyArray<sys_property_batch_role_statuses_bool_exp> | null;
  _not?: sys_property_batch_role_statuses_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batch_role_statuses_bool_exp> | null;
  property_batch_stage_id?: Int_comparison_exp | null;
  property_role_id?: Int_comparison_exp | null;
};
export type sys_property_batch_role_statuses_aggregate_bool_exp = {
  count?: sys_property_batch_role_statuses_aggregate_bool_exp_count | null;
};
export type sys_property_batch_role_statuses_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_batch_role_statuses_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_batch_role_statuses_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_submission_types_bool_exp = {
  _and?: ReadonlyArray<sys_submission_types_bool_exp> | null;
  _not?: sys_submission_types_bool_exp | null;
  _or?: ReadonlyArray<sys_submission_types_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  datasets?: sys_datasets_bool_exp | null;
  datasets_aggregate?: sys_datasets_aggregate_bool_exp | null;
  feature_flag?: String_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  properties_roles_users?: sys_properties_roles_users_bool_exp | null;
  properties_roles_users_aggregate?: sys_properties_roles_users_aggregate_bool_exp | null;
  submission_type_description?: String_comparison_exp | null;
  submission_type_id?: Int_comparison_exp | null;
  submission_type_label?: String_comparison_exp | null;
  submission_type_slug?: String_comparison_exp | null;
  submissions?: sys_submissions_bool_exp | null;
  submissions_aggregate?: sys_submissions_aggregate_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_datasets_aggregate_bool_exp = {
  bool_and?: sys_datasets_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_datasets_aggregate_bool_exp_bool_or | null;
  count?: sys_datasets_aggregate_bool_exp_count | null;
};
export type sys_datasets_aggregate_bool_exp_bool_and = {
  arguments: sys_datasets_select_column_sys_datasets_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_datasets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_datasets_aggregate_bool_exp_bool_or = {
  arguments: sys_datasets_select_column_sys_datasets_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_datasets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_datasets_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_datasets_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_datasets_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_submissions_bool_exp = {
  _and?: ReadonlyArray<sys_submissions_bool_exp> | null;
  _not?: sys_submissions_bool_exp | null;
  _or?: ReadonlyArray<sys_submissions_bool_exp> | null;
  closed_at_datetime?: timestamptz_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  due_date?: date_comparison_exp | null;
  fiscal_year_flag?: Boolean_comparison_exp | null;
  is_closed?: Boolean_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  owner_id?: Int_comparison_exp | null;
  property_batches?: sys_property_batches_bool_exp | null;
  property_batches_aggregate?: sys_property_batches_aggregate_bool_exp | null;
  property_batches_approved?: sys_property_batches_approved_bool_exp | null;
  property_batches_approved_aggregate?: sys_property_batches_approved_aggregate_bool_exp | null;
  provider?: sys_providers_bool_exp | null;
  provider_id?: Int_comparison_exp | null;
  reporting_period?: String_comparison_exp | null;
  reporting_period_end_date?: date_comparison_exp | null;
  reporting_period_start_date?: date_comparison_exp | null;
  submission_id?: Int_comparison_exp | null;
  submission_name?: String_comparison_exp | null;
  submission_status_description?: String_comparison_exp | null;
  submission_type?: sys_submission_types_bool_exp | null;
  submission_type_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  user?: sys_users_bool_exp | null;
  workflow_mode_id?: Int_comparison_exp | null;
};
export type date_comparison_exp = {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _in?: ReadonlyArray<string> | null;
  _is_null?: boolean | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nin?: ReadonlyArray<string> | null;
};
export type sys_property_batches_aggregate_bool_exp = {
  bool_and?: sys_property_batches_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_property_batches_aggregate_bool_exp_bool_or | null;
  count?: sys_property_batches_aggregate_bool_exp_count | null;
};
export type sys_property_batches_aggregate_bool_exp_bool_and = {
  arguments: sys_property_batches_select_column_sys_property_batches_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batches_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batches_aggregate_bool_exp_bool_or = {
  arguments: sys_property_batches_select_column_sys_property_batches_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batches_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batches_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_batches_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_batches_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_batches_approved_bool_exp = {
  _and?: ReadonlyArray<sys_property_batches_approved_bool_exp> | null;
  _not?: sys_property_batches_approved_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batches_approved_bool_exp> | null;
  approved_at_datetime?: timestamptz_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_approved?: Boolean_comparison_exp | null;
  is_valid?: Boolean_comparison_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  property_batch_stage_id?: Int_comparison_exp | null;
  property_id?: Int_comparison_exp | null;
  submission?: sys_submissions_bool_exp | null;
  submission_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  validated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_property_batches_approved_aggregate_bool_exp = {
  bool_and?: sys_property_batches_approved_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_property_batches_approved_aggregate_bool_exp_bool_or | null;
  count?: sys_property_batches_approved_aggregate_bool_exp_count | null;
};
export type sys_property_batches_approved_aggregate_bool_exp_bool_and = {
  arguments: sys_property_batches_approved_select_column_sys_property_batches_approved_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batches_approved_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batches_approved_aggregate_bool_exp_bool_or = {
  arguments: sys_property_batches_approved_select_column_sys_property_batches_approved_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batches_approved_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batches_approved_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_batches_approved_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_batches_approved_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_providers_bool_exp = {
  _and?: ReadonlyArray<sys_providers_bool_exp> | null;
  _not?: sys_providers_bool_exp | null;
  _or?: ReadonlyArray<sys_providers_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  ingestion_config?: jsonb_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  mapping_sets?: sys_mapping_sets_bool_exp | null;
  mapping_sets_aggregate?: sys_mapping_sets_aggregate_bool_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  properties?: sys_properties_bool_exp | null;
  properties_aggregate?: sys_properties_aggregate_bool_exp | null;
  provider_id?: Int_comparison_exp | null;
  provider_name?: String_comparison_exp | null;
  provider_type?: sys_provider_types_bool_exp | null;
  provider_type_id?: Int_comparison_exp | null;
  providers_properties?: sys_providers_properties_bool_exp | null;
  providers_properties_aggregate?: sys_providers_properties_aggregate_bool_exp | null;
  related_data_updated_at?: timestamptz_comparison_exp | null;
  report_config?: jsonb_comparison_exp | null;
  submissions?: sys_submissions_bool_exp | null;
  submissions_aggregate?: sys_submissions_aggregate_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  users?: sys_users_bool_exp | null;
  users_aggregate?: sys_users_aggregate_bool_exp | null;
};
export type sys_properties_aggregate_bool_exp = {
  bool_and?: sys_properties_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_properties_aggregate_bool_exp_bool_or | null;
  count?: sys_properties_aggregate_bool_exp_count | null;
};
export type sys_properties_aggregate_bool_exp_bool_and = {
  arguments: sys_properties_select_column_sys_properties_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_aggregate_bool_exp_bool_or = {
  arguments: sys_properties_select_column_sys_properties_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_properties_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_properties_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_provider_types_bool_exp = {
  _and?: ReadonlyArray<sys_provider_types_bool_exp> | null;
  _not?: sys_provider_types_bool_exp | null;
  _or?: ReadonlyArray<sys_provider_types_bool_exp> | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
};
export type sys_providers_properties_bool_exp = {
  _and?: ReadonlyArray<sys_providers_properties_bool_exp> | null;
  _not?: sys_providers_properties_bool_exp | null;
  _or?: ReadonlyArray<sys_providers_properties_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  inactivated_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  property?: sys_properties_bool_exp | null;
  property_id?: Int_comparison_exp | null;
  provider?: sys_providers_bool_exp | null;
  provider_id?: Int_comparison_exp | null;
  provider_property_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_providers_properties_aggregate_bool_exp = {
  bool_and?: sys_providers_properties_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_providers_properties_aggregate_bool_exp_bool_or | null;
  count?: sys_providers_properties_aggregate_bool_exp_count | null;
};
export type sys_providers_properties_aggregate_bool_exp_bool_and = {
  arguments: sys_providers_properties_select_column_sys_providers_properties_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_providers_properties_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_providers_properties_aggregate_bool_exp_bool_or = {
  arguments: sys_providers_properties_select_column_sys_providers_properties_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_providers_properties_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_providers_properties_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_providers_properties_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_providers_properties_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_submissions_aggregate_bool_exp = {
  bool_and?: sys_submissions_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_submissions_aggregate_bool_exp_bool_or | null;
  count?: sys_submissions_aggregate_bool_exp_count | null;
};
export type sys_submissions_aggregate_bool_exp_bool_and = {
  arguments: sys_submissions_select_column_sys_submissions_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_submissions_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_submissions_aggregate_bool_exp_bool_or = {
  arguments: sys_submissions_select_column_sys_submissions_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_submissions_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_submissions_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_submissions_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_submissions_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_users_aggregate_bool_exp = {
  bool_and?: sys_users_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_users_aggregate_bool_exp_bool_or | null;
  count?: sys_users_aggregate_bool_exp_count | null;
};
export type sys_users_aggregate_bool_exp_bool_and = {
  arguments: sys_users_select_column_sys_users_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_users_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_users_aggregate_bool_exp_bool_or = {
  arguments: sys_users_select_column_sys_users_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_users_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_users_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_users_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_users_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_batch_stage_transitions_aggregate_bool_exp = {
  count?: sys_property_batch_stage_transitions_aggregate_bool_exp_count | null;
};
export type sys_property_batch_stage_transitions_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_batch_stage_transitions_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_batch_stage_transitions_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sakura_user_bool_exp = {
  _and?: ReadonlyArray<sakura_user_bool_exp> | null;
  _not?: sakura_user_bool_exp | null;
  _or?: ReadonlyArray<sakura_user_bool_exp> | null;
  attributes?: json_comparison_exp | null;
  auth0_synced?: Boolean_comparison_exp | null;
  create_time?: timestamptz_comparison_exp | null;
  dashboards?: jsonb_comparison_exp | null;
  defaultPowerBiReportId?: String_comparison_exp | null;
  disabled?: Boolean_comparison_exp | null;
  email?: String_comparison_exp | null;
  email_ci?: citext_comparison_exp | null;
  first_name?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  last_modified?: timestamptz_comparison_exp | null;
  last_name?: String_comparison_exp | null;
  last_seen?: sakura_users_last_seen_bool_exp | null;
  mongo_id?: String_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  role?: String_comparison_exp | null;
  sakura_organization?: sakura_organization_bool_exp | null;
  sakura_user_permissions?: sakura_user_permission_bool_exp | null;
  sakura_user_permissions_aggregate?: sakura_user_permission_aggregate_bool_exp | null;
  sys_user?: sys_users_bool_exp | null;
};
export type json_comparison_exp = {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _in?: ReadonlyArray<any> | null;
  _is_null?: boolean | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nin?: ReadonlyArray<any> | null;
};
export type citext_comparison_exp = {
  _eq?: any | null;
  _gt?: any | null;
  _gte?: any | null;
  _ilike?: any | null;
  _in?: ReadonlyArray<any> | null;
  _iregex?: any | null;
  _is_null?: boolean | null;
  _like?: any | null;
  _lt?: any | null;
  _lte?: any | null;
  _neq?: any | null;
  _nilike?: any | null;
  _nin?: ReadonlyArray<any> | null;
  _niregex?: any | null;
  _nlike?: any | null;
  _nregex?: any | null;
  _nsimilar?: any | null;
  _regex?: any | null;
  _similar?: any | null;
};
export type sakura_users_last_seen_bool_exp = {
  _and?: ReadonlyArray<sakura_users_last_seen_bool_exp> | null;
  _not?: sakura_users_last_seen_bool_exp | null;
  _or?: ReadonlyArray<sakura_users_last_seen_bool_exp> | null;
  attributes?: json_comparison_exp | null;
  auth0_synced?: Boolean_comparison_exp | null;
  create_time?: timestamptz_comparison_exp | null;
  dashboards?: jsonb_comparison_exp | null;
  disabled?: Boolean_comparison_exp | null;
  email?: String_comparison_exp | null;
  email_ci?: citext_comparison_exp | null;
  first_name?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  last_login_at?: timestamptz_comparison_exp | null;
  last_modified?: timestamptz_comparison_exp | null;
  last_name?: String_comparison_exp | null;
  last_seen?: timestamptz_comparison_exp | null;
  mongo_id?: String_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  role?: String_comparison_exp | null;
};
export type sakura_organization_bool_exp = {
  _and?: ReadonlyArray<sakura_organization_bool_exp> | null;
  _not?: sakura_organization_bool_exp | null;
  _or?: ReadonlyArray<sakura_organization_bool_exp> | null;
  additional_props?: jsonb_comparison_exp | null;
  api_hasura_role?: String_comparison_exp | null;
  authz_version_id?: Int_comparison_exp | null;
  create_time?: timestamptz_comparison_exp | null;
  dashboards?: json_comparison_exp | null;
  description?: String_comparison_exp | null;
  disabled?: Boolean_comparison_exp | null;
  domOwner?: String_comparison_exp | null;
  hasura_access_token?: String_comparison_exp | null;
  hasura_org_name?: String_comparison_exp | null;
  hasura_role?: String_comparison_exp | null;
  id?: Int_comparison_exp | null;
  image_url?: String_comparison_exp | null;
  last_modified?: timestamptz_comparison_exp | null;
  name?: String_comparison_exp | null;
  organization_permissions?: sakura_organization_permission_bool_exp | null;
  organization_permissions_aggregate?: sakura_organization_permission_aggregate_bool_exp | null;
  plan_expires_at?: timestamptz_comparison_exp | null;
  plan_type?: String_comparison_exp | null;
  powerbi_api_url?: String_comparison_exp | null;
  powerbi_client_id?: String_comparison_exp | null;
  powerbi_client_secret?: String_comparison_exp | null;
  powerbi_tenant_id?: String_comparison_exp | null;
  publish_on_website?: Boolean_comparison_exp | null;
  salesforce_id?: String_comparison_exp | null;
  slug?: String_comparison_exp | null;
  sys_organization?: sys_organizations_bool_exp | null;
  theme_name?: String_comparison_exp | null;
  website?: String_comparison_exp | null;
  website_image_url?: String_comparison_exp | null;
};
export type sakura_organization_permission_bool_exp = {
  _and?: ReadonlyArray<sakura_organization_permission_bool_exp> | null;
  _not?: sakura_organization_permission_bool_exp | null;
  _or?: ReadonlyArray<sakura_organization_permission_bool_exp> | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  sakura_organization?: sakura_organization_bool_exp | null;
  sys_organization?: sys_organizations_bool_exp | null;
};
export type sakura_organization_permission_aggregate_bool_exp = {
  count?: sakura_organization_permission_aggregate_bool_exp_count | null;
};
export type sakura_organization_permission_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sakura_organization_permission_select_column> | null;
  distinct?: boolean | null;
  filter?: sakura_organization_permission_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sakura_user_permission_bool_exp = {
  _and?: ReadonlyArray<sakura_user_permission_bool_exp> | null;
  _not?: sakura_user_permission_bool_exp | null;
  _or?: ReadonlyArray<sakura_user_permission_bool_exp> | null;
  id?: Int_comparison_exp | null;
  name?: String_comparison_exp | null;
  user_id?: Int_comparison_exp | null;
};
export type sakura_user_permission_aggregate_bool_exp = {
  count?: sakura_user_permission_aggregate_bool_exp_count | null;
};
export type sakura_user_permission_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sakura_user_permission_select_column> | null;
  distinct?: boolean | null;
  filter?: sakura_user_permission_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_comments_aggregate_bool_exp = {
  count?: sys_comments_aggregate_bool_exp_count | null;
};
export type sys_comments_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_comments_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_comments_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_batch_datasets_bool_exp = {
  _and?: ReadonlyArray<sys_property_batch_datasets_bool_exp> | null;
  _not?: sys_property_batch_datasets_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batch_datasets_bool_exp> | null;
  approved_datetime?: timestamptz_comparison_exp | null;
  batch_file?: sys_batch_files_bool_exp | null;
  batch_file_by_dataset_id_file_id?: sys_batch_files_bool_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  dataset?: sys_datasets_bool_exp | null;
  dataset_id?: Int_comparison_exp | null;
  dataset_row_count?: Int_comparison_exp | null;
  dataset_status?: sys_dataset_statuses_bool_exp | null;
  dataset_status_id?: Int_comparison_exp | null;
  file_id?: Int_comparison_exp | null;
  is_approved?: Boolean_comparison_exp | null;
  is_valid?: Boolean_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  property_batch?: sys_property_batches_bool_exp | null;
  property_batch_dataset_id?: Int_comparison_exp | null;
  property_batch_dataset_validations?: sys_property_batch_dataset_validations_bool_exp | null;
  property_batch_dataset_validations_aggregate?: sys_property_batch_dataset_validations_aggregate_bool_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
  validated_datetime?: timestamptz_comparison_exp | null;
};
export type sys_dataset_statuses_bool_exp = {
  _and?: ReadonlyArray<sys_dataset_statuses_bool_exp> | null;
  _not?: sys_dataset_statuses_bool_exp | null;
  _or?: ReadonlyArray<sys_dataset_statuses_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  dataset_status_description?: String_comparison_exp | null;
  dataset_status_id?: Int_comparison_exp | null;
  property_batch_datasets?: sys_property_batch_datasets_bool_exp | null;
  property_batch_datasets_aggregate?: sys_property_batch_datasets_aggregate_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_property_batch_datasets_aggregate_bool_exp = {
  bool_and?: sys_property_batch_datasets_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_property_batch_datasets_aggregate_bool_exp_bool_or | null;
  count?: sys_property_batch_datasets_aggregate_bool_exp_count | null;
};
export type sys_property_batch_datasets_aggregate_bool_exp_bool_and = {
  arguments: sys_property_batch_datasets_select_column_sys_property_batch_datasets_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batch_datasets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batch_datasets_aggregate_bool_exp_bool_or = {
  arguments: sys_property_batch_datasets_select_column_sys_property_batch_datasets_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batch_datasets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batch_datasets_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_batch_datasets_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_batch_datasets_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_batch_dataset_validations_bool_exp = {
  _and?: ReadonlyArray<sys_property_batch_dataset_validations_bool_exp> | null;
  _not?: sys_property_batch_dataset_validations_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batch_dataset_validations_bool_exp> | null;
  dataset_validation_rule?: sys_dataset_validation_rules_bool_exp | null;
  dataset_validation_rule_id?: Int_comparison_exp | null;
  is_valid?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  property_batch_dataset?: sys_property_batch_datasets_bool_exp | null;
  property_batch_dataset_id?: Int_comparison_exp | null;
  property_batch_dataset_validation_id?: Int_comparison_exp | null;
  validated_datetime?: timestamp_comparison_exp | null;
  validation_mode?: String_comparison_exp | null;
  validation_params?: jsonb_comparison_exp | null;
};
export type timestamp_comparison_exp = {
  _eq?: string | null;
  _gt?: string | null;
  _gte?: string | null;
  _in?: ReadonlyArray<string> | null;
  _is_null?: boolean | null;
  _lt?: string | null;
  _lte?: string | null;
  _neq?: string | null;
  _nin?: ReadonlyArray<string> | null;
};
export type sys_property_batch_dataset_validations_aggregate_bool_exp = {
  bool_and?: sys_property_batch_dataset_validations_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_property_batch_dataset_validations_aggregate_bool_exp_bool_or | null;
  count?: sys_property_batch_dataset_validations_aggregate_bool_exp_count | null;
};
export type sys_property_batch_dataset_validations_aggregate_bool_exp_bool_and = {
  arguments: sys_property_batch_dataset_validations_select_column_sys_property_batch_dataset_validations_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batch_dataset_validations_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batch_dataset_validations_aggregate_bool_exp_bool_or = {
  arguments: sys_property_batch_dataset_validations_select_column_sys_property_batch_dataset_validations_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batch_dataset_validations_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batch_dataset_validations_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_batch_dataset_validations_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_batch_dataset_validations_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_batch_validations_aggregate_bool_exp = {
  bool_and?: sys_property_batch_validations_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_property_batch_validations_aggregate_bool_exp_bool_or | null;
  count?: sys_property_batch_validations_aggregate_bool_exp_count | null;
};
export type sys_property_batch_validations_aggregate_bool_exp_bool_and = {
  arguments: sys_property_batch_validations_select_column_sys_property_batch_validations_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batch_validations_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batch_validations_aggregate_bool_exp_bool_or = {
  arguments: sys_property_batch_validations_select_column_sys_property_batch_validations_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_batch_validations_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_batch_validations_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_batch_validations_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_batch_validations_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_batches_monitoring_bool_exp = {
  _and?: ReadonlyArray<sys_property_batches_monitoring_bool_exp> | null;
  _not?: sys_property_batches_monitoring_bool_exp | null;
  _or?: ReadonlyArray<sys_property_batches_monitoring_bool_exp> | null;
  batch_status?: String_comparison_exp | null;
  batch_validations_failed?: Int_comparison_exp | null;
  batch_validations_total?: Int_comparison_exp | null;
  dataset_validations_failed?: Int_comparison_exp | null;
  dataset_validations_total?: Int_comparison_exp | null;
  datasets_total?: Int_comparison_exp | null;
  datasets_uploaded?: Int_comparison_exp | null;
  datasets_uploaded_ratio?: float8_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  property_batch?: sys_property_batches_bool_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  submission_status?: String_comparison_exp | null;
  validations_failed?: Int_comparison_exp | null;
  validations_total?: Int_comparison_exp | null;
};
export type float8_comparison_exp = {
  _eq?: number | null;
  _gt?: number | null;
  _gte?: number | null;
  _in?: ReadonlyArray<number> | null;
  _is_null?: boolean | null;
  _lt?: number | null;
  _lte?: number | null;
  _neq?: number | null;
  _nin?: ReadonlyArray<number> | null;
};
export type sys_reminders_bool_exp = {
  _and?: ReadonlyArray<sys_reminders_bool_exp> | null;
  _not?: sys_reminders_bool_exp | null;
  _or?: ReadonlyArray<sys_reminders_bool_exp> | null;
  author_id?: Int_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_resolved?: Boolean_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  package_report?: sys_package_reports_bool_exp | null;
  package_report_id?: Int_comparison_exp | null;
  property?: sys_properties_bool_exp | null;
  property_batch?: sys_property_batches_bool_exp | null;
  property_batch_id?: Int_comparison_exp | null;
  property_id?: Int_comparison_exp | null;
  reminder_id?: Int_comparison_exp | null;
  reminders_reviewed?: sys_reminders_reviewed_bool_exp | null;
  reminders_reviewed_aggregate?: sys_reminders_reviewed_aggregate_bool_exp | null;
  submission?: sys_submissions_bool_exp | null;
  submission_id?: Int_comparison_exp | null;
  text?: String_comparison_exp | null;
  user?: sys_users_bool_exp | null;
};
export type sys_reminders_reviewed_bool_exp = {
  _and?: ReadonlyArray<sys_reminders_reviewed_bool_exp> | null;
  _not?: sys_reminders_reviewed_bool_exp | null;
  _or?: ReadonlyArray<sys_reminders_reviewed_bool_exp> | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  reminder?: sys_reminders_bool_exp | null;
  reminder_id?: Int_comparison_exp | null;
  reminders_reviewed_id?: Int_comparison_exp | null;
  reviewed_at_datetime?: timestamptz_comparison_exp | null;
  user?: sys_users_bool_exp | null;
  user_id?: Int_comparison_exp | null;
};
export type sys_reminders_reviewed_aggregate_bool_exp = {
  count?: sys_reminders_reviewed_aggregate_bool_exp_count | null;
};
export type sys_reminders_reviewed_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_reminders_reviewed_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_reminders_reviewed_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_reminders_aggregate_bool_exp = {
  bool_and?: sys_reminders_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_reminders_aggregate_bool_exp_bool_or | null;
  count?: sys_reminders_aggregate_bool_exp_count | null;
};
export type sys_reminders_aggregate_bool_exp_bool_and = {
  arguments: sys_reminders_select_column_sys_reminders_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_reminders_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_reminders_aggregate_bool_exp_bool_or = {
  arguments: sys_reminders_select_column_sys_reminders_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_reminders_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_reminders_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_reminders_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_reminders_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_properties_datasets_bool_exp = {
  _and?: ReadonlyArray<sys_properties_datasets_bool_exp> | null;
  _not?: sys_properties_datasets_bool_exp | null;
  _or?: ReadonlyArray<sys_properties_datasets_bool_exp> | null;
  dataset?: sys_datasets_bool_exp | null;
  dataset_id?: Int_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  property?: sys_properties_bool_exp | null;
  property_dataset_id?: Int_comparison_exp | null;
  property_id?: Int_comparison_exp | null;
  template_id?: String_comparison_exp | null;
};
export type sys_properties_datasets_aggregate_bool_exp = {
  bool_and?: sys_properties_datasets_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_properties_datasets_aggregate_bool_exp_bool_or | null;
  count?: sys_properties_datasets_aggregate_bool_exp_count | null;
};
export type sys_properties_datasets_aggregate_bool_exp_bool_and = {
  arguments: sys_properties_datasets_select_column_sys_properties_datasets_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_datasets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_datasets_aggregate_bool_exp_bool_or = {
  arguments: sys_properties_datasets_select_column_sys_properties_datasets_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_datasets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_datasets_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_properties_datasets_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_properties_datasets_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_properties_mapping_sets_bool_exp = {
  _and?: ReadonlyArray<sys_properties_mapping_sets_bool_exp> | null;
  _not?: sys_properties_mapping_sets_bool_exp | null;
  _or?: ReadonlyArray<sys_properties_mapping_sets_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  mapping_field_id?: Int_comparison_exp | null;
  mapping_set?: sys_mapping_sets_bool_exp | null;
  mapping_set_id?: Int_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  property?: sys_properties_bool_exp | null;
  property_id?: Int_comparison_exp | null;
  property_mapping_set_id?: Int_comparison_exp | null;
  provider_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamp_comparison_exp | null;
};
export type sys_properties_mapping_sets_aggregate_bool_exp = {
  bool_and?: sys_properties_mapping_sets_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_properties_mapping_sets_aggregate_bool_exp_bool_or | null;
  count?: sys_properties_mapping_sets_aggregate_bool_exp_count | null;
};
export type sys_properties_mapping_sets_aggregate_bool_exp_bool_and = {
  arguments: sys_properties_mapping_sets_select_column_sys_properties_mapping_sets_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_mapping_sets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_mapping_sets_aggregate_bool_exp_bool_or = {
  arguments: sys_properties_mapping_sets_select_column_sys_properties_mapping_sets_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_properties_mapping_sets_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_properties_mapping_sets_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_properties_mapping_sets_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_properties_mapping_sets_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_models_bool_exp = {
  _and?: ReadonlyArray<sys_property_models_bool_exp> | null;
  _not?: sys_property_models_bool_exp | null;
  _or?: ReadonlyArray<sys_property_models_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  inactivated_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  organizations?: sys_organizations_bool_exp | null;
  organizations_aggregate?: sys_organizations_aggregate_bool_exp | null;
  property_model_id?: Int_comparison_exp | null;
  property_model_slug?: String_comparison_exp | null;
  property_models_types?: sys_property_models_types_bool_exp | null;
  property_models_types_aggregate?: sys_property_models_types_aggregate_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_organizations_aggregate_bool_exp = {
  count?: sys_organizations_aggregate_bool_exp_count | null;
};
export type sys_organizations_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_organizations_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_organizations_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_models_types_bool_exp = {
  _and?: ReadonlyArray<sys_property_models_types_bool_exp> | null;
  _not?: sys_property_models_types_bool_exp | null;
  _or?: ReadonlyArray<sys_property_models_types_bool_exp> | null;
  approved_property_batch_stage?: sys_property_batch_stages_bool_exp | null;
  approved_property_batch_stage_id?: Int_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  inactivated_at_datetime?: timestamptz_comparison_exp | null;
  initial_property_batch_stage?: sys_property_batch_stages_bool_exp | null;
  initial_property_batch_stage_id?: Int_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  property_model?: sys_property_models_bool_exp | null;
  property_model_id?: Int_comparison_exp | null;
  property_model_type_id?: Int_comparison_exp | null;
  property_type?: sys_property_types_bool_exp | null;
  property_type_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_property_types_bool_exp = {
  _and?: ReadonlyArray<sys_property_types_bool_exp> | null;
  _not?: sys_property_types_bool_exp | null;
  _or?: ReadonlyArray<sys_property_types_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  inactivated_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  properties?: sys_properties_bool_exp | null;
  properties_aggregate?: sys_properties_aggregate_bool_exp | null;
  property_models_types?: sys_property_models_types_bool_exp | null;
  property_models_types_aggregate?: sys_property_models_types_aggregate_bool_exp | null;
  property_type_id?: Int_comparison_exp | null;
  property_type_slug?: String_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_property_models_types_aggregate_bool_exp = {
  bool_and?: sys_property_models_types_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_property_models_types_aggregate_bool_exp_bool_or | null;
  count?: sys_property_models_types_aggregate_bool_exp_count | null;
};
export type sys_property_models_types_aggregate_bool_exp_bool_and = {
  arguments: sys_property_models_types_select_column_sys_property_models_types_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_models_types_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_models_types_aggregate_bool_exp_bool_or = {
  arguments: sys_property_models_types_select_column_sys_property_models_types_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_models_types_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_models_types_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_models_types_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_models_types_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_property_relationships_aggregate_bool_exp = {
  bool_and?: sys_property_relationships_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_property_relationships_aggregate_bool_exp_bool_or | null;
  count?: sys_property_relationships_aggregate_bool_exp_count | null;
};
export type sys_property_relationships_aggregate_bool_exp_bool_and = {
  arguments: sys_property_relationships_select_column_sys_property_relationships_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_relationships_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_relationships_aggregate_bool_exp_bool_or = {
  arguments: sys_property_relationships_select_column_sys_property_relationships_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_property_relationships_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_property_relationships_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_property_relationships_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_property_relationships_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_dataset_validation_rules_aggregate_bool_exp = {
  bool_and?: sys_dataset_validation_rules_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_dataset_validation_rules_aggregate_bool_exp_bool_or | null;
  count?: sys_dataset_validation_rules_aggregate_bool_exp_count | null;
};
export type sys_dataset_validation_rules_aggregate_bool_exp_bool_and = {
  arguments: sys_dataset_validation_rules_select_column_sys_dataset_validation_rules_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_dataset_validation_rules_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_dataset_validation_rules_aggregate_bool_exp_bool_or = {
  arguments: sys_dataset_validation_rules_select_column_sys_dataset_validation_rules_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_dataset_validation_rules_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_dataset_validation_rules_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_dataset_validation_rules_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_dataset_validation_rules_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_mapping_fields_aggregate_bool_exp = {
  bool_and?: sys_mapping_fields_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_mapping_fields_aggregate_bool_exp_bool_or | null;
  count?: sys_mapping_fields_aggregate_bool_exp_count | null;
};
export type sys_mapping_fields_aggregate_bool_exp_bool_and = {
  arguments: sys_mapping_fields_select_column_sys_mapping_fields_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_mapping_fields_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_mapping_fields_aggregate_bool_exp_bool_or = {
  arguments: sys_mapping_fields_select_column_sys_mapping_fields_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_mapping_fields_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_mapping_fields_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_mapping_fields_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_mapping_fields_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_organization_account_tree_details_bool_exp = {
  _and?: ReadonlyArray<sys_organization_account_tree_details_bool_exp> | null;
  _not?: sys_organization_account_tree_details_bool_exp | null;
  _or?: ReadonlyArray<sys_organization_account_tree_details_bool_exp> | null;
  account_code_description?: String_comparison_exp | null;
  account_group_code?: String_comparison_exp | null;
  account_tree_id?: Int_comparison_exp | null;
  account_type?: String_comparison_exp | null;
  chart_of_account_id?: Int_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  gl_account_code?: String_comparison_exp | null;
  indent?: Int_comparison_exp | null;
  is_contiguous?: Boolean_comparison_exp | null;
  is_hidden?: Boolean_comparison_exp | null;
  is_negate?: Boolean_comparison_exp | null;
  is_normal_balance?: Boolean_comparison_exp | null;
  nest_level?: Int_comparison_exp | null;
  order_index?: Int_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_account_tree?: sys_organization_account_trees_bool_exp | null;
  organization_account_tree_details?: sys_organization_account_tree_details_bool_exp | null;
  organization_account_tree_details_aggregate?: sys_organization_account_tree_details_aggregate_bool_exp | null;
  organization_chart_of_account?: sys_organization_account_tree_details_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  parent_chart_of_account_id?: Int_comparison_exp | null;
  total_into_code?: String_comparison_exp | null;
  tree_path?: String_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_organization_account_trees_bool_exp = {
  _and?: ReadonlyArray<sys_organization_account_trees_bool_exp> | null;
  _not?: sys_organization_account_trees_bool_exp | null;
  _or?: ReadonlyArray<sys_organization_account_trees_bool_exp> | null;
  account_tree_name?: String_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_account_tree_details?: sys_organization_account_tree_details_bool_exp | null;
  organization_account_tree_details_aggregate?: sys_organization_account_tree_details_aggregate_bool_exp | null;
  organization_account_tree_id?: Int_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_organization_account_tree_details_aggregate_bool_exp = {
  bool_and?: sys_organization_account_tree_details_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_organization_account_tree_details_aggregate_bool_exp_bool_or | null;
  count?: sys_organization_account_tree_details_aggregate_bool_exp_count | null;
};
export type sys_organization_account_tree_details_aggregate_bool_exp_bool_and = {
  arguments: sys_organization_account_tree_details_select_column_sys_organization_account_tree_details_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_organization_account_tree_details_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_organization_account_tree_details_aggregate_bool_exp_bool_or = {
  arguments: sys_organization_account_tree_details_select_column_sys_organization_account_tree_details_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_organization_account_tree_details_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_organization_account_tree_details_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_organization_account_tree_details_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_organization_account_tree_details_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_providers_aggregate_bool_exp = {
  bool_and?: sys_providers_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_providers_aggregate_bool_exp_bool_or | null;
  count?: sys_providers_aggregate_bool_exp_count | null;
};
export type sys_providers_aggregate_bool_exp_bool_and = {
  arguments: sys_providers_select_column_sys_providers_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_providers_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_providers_aggregate_bool_exp_bool_or = {
  arguments: sys_providers_select_column_sys_providers_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_providers_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_providers_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_providers_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_providers_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sakura_user_aggregate_bool_exp = {
  bool_and?: sakura_user_aggregate_bool_exp_bool_and | null;
  bool_or?: sakura_user_aggregate_bool_exp_bool_or | null;
  count?: sakura_user_aggregate_bool_exp_count | null;
};
export type sakura_user_aggregate_bool_exp_bool_and = {
  arguments: sakura_user_select_column_sakura_user_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sakura_user_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sakura_user_aggregate_bool_exp_bool_or = {
  arguments: sakura_user_select_column_sakura_user_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sakura_user_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sakura_user_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sakura_user_select_column> | null;
  distinct?: boolean | null;
  filter?: sakura_user_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_submission_types_aggregate_bool_exp = {
  count?: sys_submission_types_aggregate_bool_exp_count | null;
};
export type sys_submission_types_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_submission_types_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_submission_types_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_mapping_set_values_bool_exp = {
  _and?: ReadonlyArray<sys_mapping_set_values_bool_exp> | null;
  _not?: sys_mapping_set_values_bool_exp | null;
  _or?: ReadonlyArray<sys_mapping_set_values_bool_exp> | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  custom_attributes?: jsonb_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  mapping_set?: sys_mapping_sets_bool_exp | null;
  mapping_set_id?: Int_comparison_exp | null;
  mapping_set_value_id?: Int_comparison_exp | null;
  organization_id?: Int_comparison_exp | null;
  source_mapping_code?: String_comparison_exp | null;
  source_mapping_label?: String_comparison_exp | null;
  target_mapping_code?: String_comparison_exp | null;
  target_mapping_label?: String_comparison_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_mapping_set_values_aggregate_bool_exp = {
  bool_and?: sys_mapping_set_values_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_mapping_set_values_aggregate_bool_exp_bool_or | null;
  count?: sys_mapping_set_values_aggregate_bool_exp_count | null;
};
export type sys_mapping_set_values_aggregate_bool_exp_bool_and = {
  arguments: sys_mapping_set_values_select_column_sys_mapping_set_values_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_mapping_set_values_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_mapping_set_values_aggregate_bool_exp_bool_or = {
  arguments: sys_mapping_set_values_select_column_sys_mapping_set_values_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_mapping_set_values_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_mapping_set_values_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_mapping_set_values_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_mapping_set_values_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_organization_master_chart_of_accounts_bool_exp = {
  _and?: ReadonlyArray<sys_organization_master_chart_of_accounts_bool_exp> | null;
  _not?: sys_organization_master_chart_of_accounts_bool_exp | null;
  _or?: ReadonlyArray<sys_organization_master_chart_of_accounts_bool_exp> | null;
  chart_of_accounts_description?: String_comparison_exp | null;
  chart_of_accounts_name?: String_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  master_chart_of_account_id?: Int_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  organization_master_chart_of_account_values?: sys_organization_master_chart_of_account_values_bool_exp | null;
  organization_master_chart_of_account_values_aggregate?: sys_organization_master_chart_of_account_values_aggregate_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_organization_master_chart_of_account_values_bool_exp = {
  _and?: ReadonlyArray<sys_organization_master_chart_of_account_values_bool_exp> | null;
  _not?: sys_organization_master_chart_of_account_values_bool_exp | null;
  _or?: ReadonlyArray<sys_organization_master_chart_of_account_values_bool_exp> | null;
  account_code?: String_comparison_exp | null;
  account_hold?: Boolean_comparison_exp | null;
  account_name?: String_comparison_exp | null;
  created_at_datetime?: timestamptz_comparison_exp | null;
  is_active?: Boolean_comparison_exp | null;
  master_chart_of_account_id?: Int_comparison_exp | null;
  master_chart_of_account_values_id?: Int_comparison_exp | null;
  organization?: sys_organizations_bool_exp | null;
  organization_id?: Int_comparison_exp | null;
  organization_master_chart_of_accounts?: sys_organization_master_chart_of_accounts_bool_exp | null;
  updated_at_datetime?: timestamptz_comparison_exp | null;
};
export type sys_organization_master_chart_of_account_values_aggregate_bool_exp = {
  bool_and?: sys_organization_master_chart_of_account_values_aggregate_bool_exp_bool_and | null;
  bool_or?: sys_organization_master_chart_of_account_values_aggregate_bool_exp_bool_or | null;
  count?: sys_organization_master_chart_of_account_values_aggregate_bool_exp_count | null;
};
export type sys_organization_master_chart_of_account_values_aggregate_bool_exp_bool_and = {
  arguments: sys_organization_master_chart_of_account_values_select_column_sys_organization_master_chart_of_account_values_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_organization_master_chart_of_account_values_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_organization_master_chart_of_account_values_aggregate_bool_exp_bool_or = {
  arguments: sys_organization_master_chart_of_account_values_select_column_sys_organization_master_chart_of_account_values_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null;
  filter?: sys_organization_master_chart_of_account_values_bool_exp | null;
  predicate: Boolean_comparison_exp;
};
export type sys_organization_master_chart_of_account_values_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sys_organization_master_chart_of_account_values_select_column> | null;
  distinct?: boolean | null;
  filter?: sys_organization_master_chart_of_account_values_bool_exp | null;
  predicate: Int_comparison_exp;
};
export type sys_organizations_obj_rel_insert_input = {
  data: sys_organizations_insert_input;
  on_conflict?: sys_organizations_on_conflict | null;
};
export type sys_organizations_insert_input = {
  batch_files?: sys_batch_files_arr_rel_insert_input | null;
  created_at_datetime?: string | null;
  datasets?: sys_datasets_arr_rel_insert_input | null;
  flatfile_team_name?: string | null;
  mapping_fields?: sys_mapping_fields_arr_rel_insert_input | null;
  mapping_sets?: sys_mapping_sets_arr_rel_insert_input | null;
  organization_account_tree_details?: sys_organization_account_tree_details_arr_rel_insert_input | null;
  organization_description?: string | null;
  organization_id?: number | null;
  organization_label?: string | null;
  organization_slug?: string | null;
  properties_datasets?: sys_properties_datasets_arr_rel_insert_input | null;
  properties_roles_users?: sys_properties_roles_users_arr_rel_insert_input | null;
  property_batch_datasets?: sys_property_batch_datasets_arr_rel_insert_input | null;
  property_batch_stage_transitions?: sys_property_batch_stage_transitions_arr_rel_insert_input | null;
  property_batches?: sys_property_batches_arr_rel_insert_input | null;
  property_model?: sys_property_models_obj_rel_insert_input | null;
  property_model_id?: number | null;
  providers?: sys_providers_arr_rel_insert_input | null;
  sakura_organization?: sakura_organization_obj_rel_insert_input | null;
  sakura_users?: sakura_user_arr_rel_insert_input | null;
  settings?: any | null;
  submission_types?: sys_submission_types_arr_rel_insert_input | null;
  submissions?: sys_submissions_arr_rel_insert_input | null;
  updated_at_datetime?: string | null;
};
export type sys_batch_files_arr_rel_insert_input = {
  data: ReadonlyArray<sys_batch_files_insert_input>;
  on_conflict?: sys_batch_files_on_conflict | null;
};
export type sys_batch_files_insert_input = {
  created_at_datetime?: string | null;
  dataset?: sys_datasets_obj_rel_insert_input | null;
  dataset_id?: number | null;
  file_id?: number | null;
  file_name?: string | null;
  file_reference_souce?: string | null;
  file_source?: string | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  processed_row_count?: number | null;
  property_batch_datasets?: sys_property_batch_datasets_arr_rel_insert_input | null;
  property_batch_datasets_by_dataset_id_file_id?: sys_property_batch_datasets_arr_rel_insert_input | null;
  row_count?: number | null;
  submitter?: sys_users_obj_rel_insert_input | null;
  submitter_id?: number | null;
  updated_at_datetime?: string | null;
};
export type sys_datasets_obj_rel_insert_input = {
  data: sys_datasets_insert_input;
  on_conflict?: sys_datasets_on_conflict | null;
};
export type sys_datasets_insert_input = {
  batch_files?: sys_batch_files_arr_rel_insert_input | null;
  created_at_datetime?: string | null;
  dataset_description?: string | null;
  dataset_id?: number | null;
  dataset_label?: string | null;
  dataset_object?: any | null;
  dataset_slug?: string | null;
  dataset_validation_rules?: sys_dataset_validation_rules_arr_rel_insert_input | null;
  feature_flag?: string | null;
  flatfile_embed_id?: string | null;
  flatfile_private_key?: string | null;
  is_tsa_enabled?: boolean | null;
  managed_by?: string | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  package_reports_datasets?: sys_package_reports_datasets_arr_rel_insert_input | null;
  properties_datasets?: sys_properties_datasets_arr_rel_insert_input | null;
  property_batch_datasets?: sys_property_batch_datasets_arr_rel_insert_input | null;
  submission_type?: sys_submission_types_obj_rel_insert_input | null;
  submission_type_id?: number | null;
  tsa_flatfile_embed_id?: string | null;
  tsa_flatfile_private_key?: string | null;
  unified_ingest_schemas?: any | null;
  updated_at_datetime?: string | null;
};
export type sys_dataset_validation_rules_arr_rel_insert_input = {
  data: ReadonlyArray<sys_dataset_validation_rules_insert_input>;
  on_conflict?: sys_dataset_validation_rules_on_conflict | null;
};
export type sys_dataset_validation_rules_insert_input = {
  banner_config?: any | null;
  created_at_datetime?: string | null;
  dataset?: sys_datasets_obj_rel_insert_input | null;
  dataset_id?: number | null;
  dataset_validation_rule_id?: number | null;
  feature_flag?: string | null;
  is_active?: boolean | null;
  organization_id?: number | null;
  properties_dataset_validation_rules?: sys_properties_dataset_validation_rules_arr_rel_insert_input | null;
  property_batch_dataset_validations?: sys_property_batch_dataset_validations_arr_rel_insert_input | null;
  report?: sys_reports_obj_rel_insert_input | null;
  report_id?: number | null;
  updated_at_datetime?: string | null;
  validation_description?: string | null;
  validation_label?: string | null;
  validation_params?: any | null;
  validation_slug?: string | null;
  validation_type?: string | null;
};
export type sys_properties_dataset_validation_rules_arr_rel_insert_input = {
  data: ReadonlyArray<sys_properties_dataset_validation_rules_insert_input>;
  on_conflict?: sys_properties_dataset_validation_rules_on_conflict | null;
};
export type sys_properties_dataset_validation_rules_insert_input = {
  created_at_datetime?: string | null;
  dataset_validation_rule?: sys_dataset_validation_rules_obj_rel_insert_input | null;
  dataset_validation_rule_id?: number | null;
  is_active?: boolean | null;
  organization_id?: number | null;
  property?: sys_properties_obj_rel_insert_input | null;
  property_dataset_validation_rule_id?: number | null;
  property_id?: number | null;
  updated_at_datetime?: string | null;
  validation_mode?: string | null;
};
export type sys_dataset_validation_rules_obj_rel_insert_input = {
  data: sys_dataset_validation_rules_insert_input;
  on_conflict?: sys_dataset_validation_rules_on_conflict | null;
};
export type sys_dataset_validation_rules_on_conflict = {
  constraint: sys_dataset_validation_rules_constraint;
  update_columns: ReadonlyArray<sys_dataset_validation_rules_update_column>;
  where?: sys_dataset_validation_rules_bool_exp | null;
};
export type sys_properties_obj_rel_insert_input = {
  data: sys_properties_insert_input;
  on_conflict?: sys_properties_on_conflict | null;
};
export type sys_properties_insert_input = {
  address?: string | null;
  created_at_datetime?: string | null;
  custom_attributes?: any | null;
  entity_id?: string | null;
  entity_name?: string | null;
  from_property_relationships?: sys_property_relationships_arr_rel_insert_input | null;
  fund?: string | null;
  is_active?: boolean | null;
  is_sold?: boolean | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  properties_batch_validation_rules?: sys_properties_batch_validation_rules_arr_rel_insert_input | null;
  properties_dataset_validation_rules?: sys_properties_dataset_validation_rules_arr_rel_insert_input | null;
  properties_datasets?: sys_properties_datasets_arr_rel_insert_input | null;
  properties_mapping_sets?: sys_properties_mapping_sets_arr_rel_insert_input | null;
  properties_roles_users?: sys_properties_roles_users_arr_rel_insert_input | null;
  property_batches?: sys_property_batches_arr_rel_insert_input | null;
  property_code?: string | null;
  property_id?: number | null;
  property_name?: string | null;
  property_type?: sys_property_types_obj_rel_insert_input | null;
  property_type_id?: number | null;
  provider?: sys_providers_obj_rel_insert_input | null;
  provider_id?: number | null;
  providers_properties?: sys_providers_properties_arr_rel_insert_input | null;
  reminders?: sys_reminders_arr_rel_insert_input | null;
  sold_date?: string | null;
  to_property_relationships?: sys_property_relationships_arr_rel_insert_input | null;
  type?: string | null;
  updated_at_datetime?: string | null;
};
export type sys_property_relationships_arr_rel_insert_input = {
  data: ReadonlyArray<sys_property_relationships_insert_input>;
  on_conflict?: sys_property_relationships_on_conflict | null;
};
export type sys_property_relationships_insert_input = {
  created_at_datetime?: string | null;
  from_property?: sys_properties_obj_rel_insert_input | null;
  inactivated_at_datetime?: string | null;
  organization_id?: number | null;
  properties_flattened_union?: sys_properties_flattened_union_obj_rel_insert_input | null;
  property_id_from?: number | null;
  property_id_to?: number | null;
  property_relationship_id?: number | null;
  to_property?: sys_properties_obj_rel_insert_input | null;
  updated_at_datetime?: string | null;
};
export type sys_properties_flattened_union_obj_rel_insert_input = {
  data: sys_properties_flattened_union_insert_input;
};
export type sys_properties_flattened_union_insert_input = {
  address?: string | null;
  created_at_datetime?: string | null;
  custom_attributes?: any | null;
  datasets_count?: number | null;
  datasets_property_id?: number | null;
  entity_id?: string | null;
  entity_name?: string | null;
  fund?: string | null;
  investment_properties_dataset_validation_rules?: sys_properties_dataset_validation_rules_arr_rel_insert_input | null;
  is_active?: boolean | null;
  is_ready_for_submission?: boolean | null;
  is_sold?: boolean | null;
  mappings_count?: number | null;
  mappings_property_id?: number | null;
  organization_id?: number | null;
  parent_property?: sys_properties_obj_rel_insert_input | null;
  parent_property_id?: number | null;
  parent_property_type_id?: number | null;
  properties_batch_validation_rules?: sys_properties_batch_validation_rules_arr_rel_insert_input | null;
  properties_dataset_validation_rules?: sys_properties_dataset_validation_rules_arr_rel_insert_input | null;
  properties_datasets?: sys_properties_datasets_arr_rel_insert_input | null;
  properties_mapping_sets?: sys_properties_mapping_sets_arr_rel_insert_input | null;
  properties_roles_users?: sys_properties_roles_users_arr_rel_insert_input | null;
  property_batches?: sys_property_batches_arr_rel_insert_input | null;
  property_code?: string | null;
  property_id?: number | null;
  property_mapping?: string | null;
  property_model_id?: number | null;
  property_models?: sys_property_models_obj_rel_insert_input | null;
  property_name?: string | null;
  property_type_id?: number | null;
  provider?: sys_providers_obj_rel_insert_input | null;
  provider_id?: number | null;
  sold_date?: string | null;
  type?: string | null;
  updated_at_datetime?: string | null;
  users_count?: number | null;
  users_property_id?: number | null;
};
export type sys_properties_batch_validation_rules_arr_rel_insert_input = {
  data: ReadonlyArray<sys_properties_batch_validation_rules_insert_input>;
  on_conflict?: sys_properties_batch_validation_rules_on_conflict | null;
};
export type sys_properties_batch_validation_rules_insert_input = {
  batch_validation_rule?: sys_batch_validation_rules_obj_rel_insert_input | null;
  batch_validation_rule_id?: number | null;
  created_at_datetime?: string | null;
  is_active?: boolean | null;
  organization_id?: number | null;
  property?: sys_properties_obj_rel_insert_input | null;
  property_batch_validation_rule_id?: number | null;
  property_batch_validations?: sys_property_batch_validations_arr_rel_insert_input | null;
  property_id?: number | null;
  updated_at_datetime?: string | null;
  validation_mode?: string | null;
};
export type sys_batch_validation_rules_obj_rel_insert_input = {
  data: sys_batch_validation_rules_insert_input;
  on_conflict?: sys_batch_validation_rules_on_conflict | null;
};
export type sys_batch_validation_rules_insert_input = {
  banner_config?: any | null;
  batch_validation_rule_id?: number | null;
  created_at_datetime?: string | null;
  datasets_batch_validation_rules?: sys_datasets_batch_validation_rules_arr_rel_insert_input | null;
  feature_flag?: string | null;
  is_active?: boolean | null;
  organization_id?: number | null;
  organization_params?: any | null;
  properties_batch_validation_rules?: sys_properties_batch_validation_rules_arr_rel_insert_input | null;
  report?: sys_reports_obj_rel_insert_input | null;
  report_id?: number | null;
  updated_at_datetime?: string | null;
  validation_description?: string | null;
  validation_label?: string | null;
  validation_params?: any | null;
  validation_slug?: string | null;
  validation_type?: string | null;
};
export type sys_datasets_batch_validation_rules_arr_rel_insert_input = {
  data: ReadonlyArray<sys_datasets_batch_validation_rules_insert_input>;
  on_conflict?: sys_datasets_batch_validation_rules_on_conflict | null;
};
export type sys_datasets_batch_validation_rules_insert_input = {
  batch_validation_rule_id?: number | null;
  dataset?: sys_datasets_obj_rel_insert_input | null;
  dataset_batch_validation_rule_id?: number | null;
  dataset_id?: number | null;
  is_active?: boolean | null;
  organization_id?: number | null;
};
export type sys_datasets_batch_validation_rules_on_conflict = {
  constraint: sys_datasets_batch_validation_rules_constraint;
  update_columns: ReadonlyArray<sys_datasets_batch_validation_rules_update_column>;
  where?: sys_datasets_batch_validation_rules_bool_exp | null;
};
export type sys_reports_obj_rel_insert_input = {
  data: sys_reports_insert_input;
  on_conflict?: sys_reports_on_conflict | null;
};
export type sys_reports_insert_input = {
  organization_id?: number | null;
  report_config?: any | null;
  report_id?: number | null;
  report_slug?: string | null;
};
export type sys_reports_on_conflict = {
  constraint: sys_reports_constraint;
  update_columns: ReadonlyArray<sys_reports_update_column>;
  where?: sys_reports_bool_exp | null;
};
export type sys_batch_validation_rules_on_conflict = {
  constraint: sys_batch_validation_rules_constraint;
  update_columns: ReadonlyArray<sys_batch_validation_rules_update_column>;
  where?: sys_batch_validation_rules_bool_exp | null;
};
export type sys_property_batch_validations_arr_rel_insert_input = {
  data: ReadonlyArray<sys_property_batch_validations_insert_input>;
  on_conflict?: sys_property_batch_validations_on_conflict | null;
};
export type sys_property_batch_validations_insert_input = {
  created_at_datetime?: string | null;
  is_valid?: boolean | null;
  organization_id?: number | null;
  properties_batch_validation_rule?: sys_properties_batch_validation_rules_obj_rel_insert_input | null;
  property_batch?: sys_property_batches_obj_rel_insert_input | null;
  property_batch_id?: number | null;
  property_batch_validation_id?: number | null;
  property_batch_validation_rule_id?: number | null;
  updated_at_datetime?: string | null;
  validation_mode?: string | null;
  validation_params?: any | null;
};
export type sys_properties_batch_validation_rules_obj_rel_insert_input = {
  data: sys_properties_batch_validation_rules_insert_input;
  on_conflict?: sys_properties_batch_validation_rules_on_conflict | null;
};
export type sys_properties_batch_validation_rules_on_conflict = {
  constraint: sys_properties_batch_validation_rules_constraint;
  update_columns: ReadonlyArray<sys_properties_batch_validation_rules_update_column>;
  where?: sys_properties_batch_validation_rules_bool_exp | null;
};
export type sys_property_batches_obj_rel_insert_input = {
  data: sys_property_batches_insert_input;
  on_conflict?: sys_property_batches_on_conflict | null;
};
export type sys_property_batches_insert_input = {
  approved_at_datetime?: string | null;
  comments?: sys_comments_arr_rel_insert_input | null;
  created_at_datetime?: string | null;
  is_approved?: boolean | null;
  is_valid?: boolean | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  properties_flattened_union?: sys_properties_flattened_union_obj_rel_insert_input | null;
  property?: sys_properties_obj_rel_insert_input | null;
  property_batch_datasets?: sys_property_batch_datasets_arr_rel_insert_input | null;
  property_batch_id?: number | null;
  property_batch_package_reports?: sys_property_batch_package_reports_arr_rel_insert_input | null;
  property_batch_stage?: sys_property_batch_stages_obj_rel_insert_input | null;
  property_batch_stage_id?: number | null;
  property_batch_stage_transitions?: sys_property_batch_stage_transitions_arr_rel_insert_input | null;
  property_batch_validations?: sys_property_batch_validations_arr_rel_insert_input | null;
  property_batches_monitoring?: sys_property_batches_monitoring_obj_rel_insert_input | null;
  property_id?: number | null;
  reminders?: sys_reminders_arr_rel_insert_input | null;
  submission?: sys_submissions_obj_rel_insert_input | null;
  submission_id?: number | null;
  updated_at_datetime?: string | null;
  validated_at_datetime?: string | null;
};
export type sys_comments_arr_rel_insert_input = {
  data: ReadonlyArray<sys_comments_insert_input>;
  on_conflict?: sys_comments_on_conflict | null;
};
export type sys_comments_insert_input = {
  author_id?: number | null;
  comment_id?: number | null;
  comment_type?: sys_comment_types_obj_rel_insert_input | null;
  comment_type_id?: number | null;
  created_at_datetime?: string | null;
  package_report?: sys_package_reports_obj_rel_insert_input | null;
  package_report_id?: number | null;
  property_batch?: sys_property_batches_obj_rel_insert_input | null;
  property_batch_id?: number | null;
  text?: string | null;
  user?: sys_users_obj_rel_insert_input | null;
};
export type sys_comment_types_obj_rel_insert_input = {
  data: sys_comment_types_insert_input;
  on_conflict?: sys_comment_types_on_conflict | null;
};
export type sys_comment_types_insert_input = {
  comment_type_id?: number | null;
  comment_type_slug?: string | null;
};
export type sys_comment_types_on_conflict = {
  constraint: sys_comment_types_constraint;
  update_columns: ReadonlyArray<sys_comment_types_update_column>;
  where?: sys_comment_types_bool_exp | null;
};
export type sys_package_reports_obj_rel_insert_input = {
  data: sys_package_reports_insert_input;
  on_conflict?: sys_package_reports_on_conflict | null;
};
export type sys_package_reports_insert_input = {
  created_at_datetime?: string | null;
  is_active?: boolean | null;
  organization_id?: number | null;
  organization_params?: any | null;
  package_report_description?: string | null;
  package_report_id?: number | null;
  package_report_label?: string | null;
  package_report_slug?: string | null;
  package_reports_datasets?: sys_package_reports_datasets_arr_rel_insert_input | null;
  property_batch_package_reports?: sys_property_batch_package_reports_arr_rel_insert_input | null;
  report?: sys_reports_obj_rel_insert_input | null;
  report_id?: number | null;
  updated_at_datetime?: string | null;
};
export type sys_package_reports_datasets_arr_rel_insert_input = {
  data: ReadonlyArray<sys_package_reports_datasets_insert_input>;
  on_conflict?: sys_package_reports_datasets_on_conflict | null;
};
export type sys_package_reports_datasets_insert_input = {
  dataset?: sys_datasets_obj_rel_insert_input | null;
  dataset_id?: number | null;
  package_report?: sys_package_reports_obj_rel_insert_input | null;
  package_report_dataset_id?: number | null;
  package_report_id?: number | null;
};
export type sys_package_reports_datasets_on_conflict = {
  constraint: sys_package_reports_datasets_constraint;
  update_columns: ReadonlyArray<sys_package_reports_datasets_update_column>;
  where?: sys_package_reports_datasets_bool_exp | null;
};
export type sys_property_batch_package_reports_arr_rel_insert_input = {
  data: ReadonlyArray<sys_property_batch_package_reports_insert_input>;
  on_conflict?: sys_property_batch_package_reports_on_conflict | null;
};
export type sys_property_batch_package_reports_insert_input = {
  is_reviewed?: boolean | null;
  organization_id?: number | null;
  package_report?: sys_package_reports_obj_rel_insert_input | null;
  package_report_id?: number | null;
  property_batch?: sys_property_batches_obj_rel_insert_input | null;
  property_batch_id?: number | null;
  property_batch_package_report_id?: number | null;
  property_batch_stage?: sys_property_batch_stages_obj_rel_insert_input | null;
  property_batch_stage_id?: number | null;
  reviewed_datetime?: string | null;
  user_id?: number | null;
};
export type sys_property_batch_stages_obj_rel_insert_input = {
  data: sys_property_batch_stages_insert_input;
  on_conflict?: sys_property_batch_stages_on_conflict | null;
};
export type sys_property_batch_stages_insert_input = {
  created_at_datetime?: string | null;
  property_batch_package_reports?: sys_property_batch_package_reports_arr_rel_insert_input | null;
  property_batch_stage_description?: string | null;
  property_batch_stage_id?: number | null;
  property_batch_stage_transitions?: sys_property_batch_stage_transitions_arr_rel_insert_input | null;
  property_batches?: sys_property_batches_arr_rel_insert_input | null;
  updated_at_datetime?: string | null;
};
export type sys_property_batch_stage_transitions_arr_rel_insert_input = {
  data: ReadonlyArray<sys_property_batch_stage_transitions_insert_input>;
  on_conflict?: sys_property_batch_stage_transitions_on_conflict | null;
};
export type sys_property_batch_stage_transitions_insert_input = {
  action?: string | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  property_batch?: sys_property_batches_obj_rel_insert_input | null;
  property_batch_id?: number | null;
  property_batch_stage?: sys_property_batch_stages_obj_rel_insert_input | null;
  property_batch_stage_id?: number | null;
  property_batch_stage_transition_id?: number | null;
  transition_datetime?: string | null;
  user?: sys_users_obj_rel_insert_input | null;
  user_id?: number | null;
};
export type sys_users_obj_rel_insert_input = {
  data: sys_users_insert_input;
  on_conflict?: sys_users_on_conflict | null;
};
export type sys_users_insert_input = {
  batch_files?: sys_batch_files_arr_rel_insert_input | null;
  created_at_datetime?: string | null;
  is_active?: boolean | null;
  notification_recipients?: sys_notification_recipients_arr_rel_insert_input | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  properties_roles_users?: sys_properties_roles_users_arr_rel_insert_input | null;
  property_batch_stage_transitions?: sys_property_batch_stage_transitions_arr_rel_insert_input | null;
  provider?: sys_providers_obj_rel_insert_input | null;
  provider_id?: number | null;
  sakura_user?: sakura_user_obj_rel_insert_input | null;
  submissions?: sys_submissions_arr_rel_insert_input | null;
  updated_at_datetime?: string | null;
  user?: sakura_user_obj_rel_insert_input | null;
  user_id?: number | null;
  users_last_seen?: sakura_users_last_seen_obj_rel_insert_input | null;
};
export type sys_notification_recipients_arr_rel_insert_input = {
  data: ReadonlyArray<sys_notification_recipients_insert_input>;
  on_conflict?: sys_notification_recipients_on_conflict | null;
};
export type sys_notification_recipients_insert_input = {
  failed_count?: number | null;
  last_failed_attempt_at_datetime?: string | null;
  notification?: sys_notifications_obj_rel_insert_input | null;
  notification_id?: number | null;
  sent_at_datetime?: string | null;
  user_id?: number | null;
};
export type sys_notifications_obj_rel_insert_input = {
  data: sys_notifications_insert_input;
  on_conflict?: sys_notifications_on_conflict | null;
};
export type sys_notifications_insert_input = {
  created_at_datetime?: string | null;
  notification_detail_data?: any | null;
  notification_group_data?: any | null;
  notification_id?: number | null;
  notification_recipients?: sys_notification_recipients_arr_rel_insert_input | null;
  notification_source_data?: any | null;
  notification_type?: sys_notification_types_obj_rel_insert_input | null;
  notification_type_id?: number | null;
};
export type sys_notification_types_obj_rel_insert_input = {
  data: sys_notification_types_insert_input;
  on_conflict?: sys_notification_types_on_conflict | null;
};
export type sys_notification_types_insert_input = {
  notification_type_id?: number | null;
  notification_type_slug?: string | null;
  notifications?: sys_notifications_arr_rel_insert_input | null;
  priority?: number | null;
};
export type sys_notifications_arr_rel_insert_input = {
  data: ReadonlyArray<sys_notifications_insert_input>;
  on_conflict?: sys_notifications_on_conflict | null;
};
export type sys_notifications_on_conflict = {
  constraint: sys_notifications_constraint;
  update_columns: ReadonlyArray<sys_notifications_update_column>;
  where?: sys_notifications_bool_exp | null;
};
export type sys_notification_types_on_conflict = {
  constraint: sys_notification_types_constraint;
  update_columns: ReadonlyArray<sys_notification_types_update_column>;
  where?: sys_notification_types_bool_exp | null;
};
export type sys_notification_recipients_on_conflict = {
  constraint: sys_notification_recipients_constraint;
  update_columns: ReadonlyArray<sys_notification_recipients_update_column>;
  where?: sys_notification_recipients_bool_exp | null;
};
export type sys_properties_roles_users_arr_rel_insert_input = {
  data: ReadonlyArray<sys_properties_roles_users_insert_input>;
  on_conflict?: sys_properties_roles_users_on_conflict | null;
};
export type sys_properties_roles_users_insert_input = {
  created_at_datetime?: string | null;
  is_active?: boolean | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  property?: sys_properties_obj_rel_insert_input | null;
  property_id?: number | null;
  property_role?: sys_property_roles_obj_rel_insert_input | null;
  property_role_id?: number | null;
  property_role_user_id?: number | null;
  provider_id?: number | null;
  submission_type?: sys_submission_types_obj_rel_insert_input | null;
  submission_type_id?: number | null;
  updated_at_datetime?: string | null;
  user?: sys_users_obj_rel_insert_input | null;
  user_id?: number | null;
};
export type sys_property_roles_obj_rel_insert_input = {
  data: sys_property_roles_insert_input;
  on_conflict?: sys_property_roles_on_conflict | null;
};
export type sys_property_roles_insert_input = {
  properties_roles_users?: sys_properties_roles_users_arr_rel_insert_input | null;
  property_batch_role_status?: sys_property_batch_role_statuses_arr_rel_insert_input | null;
  property_role_id?: number | null;
  property_role_label?: string | null;
  property_role_set?: string | null;
  property_role_slug?: string | null;
};
export type sys_property_batch_role_statuses_arr_rel_insert_input = {
  data: ReadonlyArray<sys_property_batch_role_statuses_insert_input>;
  on_conflict?: sys_property_batch_role_statuses_on_conflict | null;
};
export type sys_property_batch_role_statuses_insert_input = {
  property_batch_stage_id?: number | null;
  property_role_id?: number | null;
};
export type sys_property_batch_role_statuses_on_conflict = {
  constraint: sys_property_batch_role_statuses_constraint;
  update_columns: ReadonlyArray<sys_property_batch_role_statuses_update_column>;
  where?: sys_property_batch_role_statuses_bool_exp | null;
};
export type sys_property_roles_on_conflict = {
  constraint: sys_property_roles_constraint;
  update_columns: ReadonlyArray<sys_property_roles_update_column>;
  where?: sys_property_roles_bool_exp | null;
};
export type sys_submission_types_obj_rel_insert_input = {
  data: sys_submission_types_insert_input;
  on_conflict?: sys_submission_types_on_conflict | null;
};
export type sys_submission_types_insert_input = {
  created_at_datetime?: string | null;
  datasets?: sys_datasets_arr_rel_insert_input | null;
  feature_flag?: string | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  properties_roles_users?: sys_properties_roles_users_arr_rel_insert_input | null;
  submission_type_description?: string | null;
  submission_type_id?: number | null;
  submission_type_label?: string | null;
  submission_type_slug?: string | null;
  submissions?: sys_submissions_arr_rel_insert_input | null;
  updated_at_datetime?: string | null;
};
export type sys_datasets_arr_rel_insert_input = {
  data: ReadonlyArray<sys_datasets_insert_input>;
  on_conflict?: sys_datasets_on_conflict | null;
};
export type sys_datasets_on_conflict = {
  constraint: sys_datasets_constraint;
  update_columns: ReadonlyArray<sys_datasets_update_column>;
  where?: sys_datasets_bool_exp | null;
};
export type sys_submissions_arr_rel_insert_input = {
  data: ReadonlyArray<sys_submissions_insert_input>;
  on_conflict?: sys_submissions_on_conflict | null;
};
export type sys_submissions_insert_input = {
  closed_at_datetime?: string | null;
  created_at_datetime?: string | null;
  due_date?: string | null;
  fiscal_year_flag?: boolean | null;
  is_closed?: boolean | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  owner_id?: number | null;
  property_batches?: sys_property_batches_arr_rel_insert_input | null;
  property_batches_approved?: sys_property_batches_approved_arr_rel_insert_input | null;
  provider?: sys_providers_obj_rel_insert_input | null;
  provider_id?: number | null;
  reporting_period?: string | null;
  reporting_period_end_date?: string | null;
  reporting_period_start_date?: string | null;
  submission_id?: number | null;
  submission_name?: string | null;
  submission_type?: sys_submission_types_obj_rel_insert_input | null;
  submission_type_id?: number | null;
  updated_at_datetime?: string | null;
  user?: sys_users_obj_rel_insert_input | null;
  workflow_mode_id?: number | null;
};
export type sys_property_batches_arr_rel_insert_input = {
  data: ReadonlyArray<sys_property_batches_insert_input>;
  on_conflict?: sys_property_batches_on_conflict | null;
};
export type sys_property_batches_on_conflict = {
  constraint: sys_property_batches_constraint;
  update_columns: ReadonlyArray<sys_property_batches_update_column>;
  where?: sys_property_batches_bool_exp | null;
};
export type sys_property_batches_approved_arr_rel_insert_input = {
  data: ReadonlyArray<sys_property_batches_approved_insert_input>;
};
export type sys_property_batches_approved_insert_input = {
  approved_at_datetime?: string | null;
  created_at_datetime?: string | null;
  is_approved?: boolean | null;
  is_valid?: boolean | null;
  property_batch_id?: number | null;
  property_batch_stage_id?: number | null;
  property_id?: number | null;
  submission?: sys_submissions_obj_rel_insert_input | null;
  submission_id?: number | null;
  updated_at_datetime?: string | null;
  validated_at_datetime?: string | null;
};
export type sys_submissions_obj_rel_insert_input = {
  data: sys_submissions_insert_input;
  on_conflict?: sys_submissions_on_conflict | null;
};
export type sys_submissions_on_conflict = {
  constraint: sys_submissions_constraint;
  update_columns: ReadonlyArray<sys_submissions_update_column>;
  where?: sys_submissions_bool_exp | null;
};
export type sys_providers_obj_rel_insert_input = {
  data: sys_providers_insert_input;
  on_conflict?: sys_providers_on_conflict | null;
};
export type sys_providers_insert_input = {
  created_at_datetime?: string | null;
  ingestion_config?: any | null;
  is_active?: boolean | null;
  mapping_sets?: sys_mapping_sets_arr_rel_insert_input | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  properties?: sys_properties_arr_rel_insert_input | null;
  provider_id?: number | null;
  provider_name?: string | null;
  provider_type?: sys_provider_types_obj_rel_insert_input | null;
  provider_type_id?: number | null;
  providers_properties?: sys_providers_properties_arr_rel_insert_input | null;
  related_data_updated_at?: string | null;
  report_config?: any | null;
  submissions?: sys_submissions_arr_rel_insert_input | null;
  updated_at_datetime?: string | null;
  users?: sys_users_arr_rel_insert_input | null;
};
export type sys_properties_arr_rel_insert_input = {
  data: ReadonlyArray<sys_properties_insert_input>;
  on_conflict?: sys_properties_on_conflict | null;
};
export type sys_properties_on_conflict = {
  constraint: sys_properties_constraint;
  update_columns: ReadonlyArray<sys_properties_update_column>;
  where?: sys_properties_bool_exp | null;
};
export type sys_provider_types_obj_rel_insert_input = {
  data: sys_provider_types_insert_input;
  on_conflict?: sys_provider_types_on_conflict | null;
};
export type sys_provider_types_insert_input = {
  id?: number | null;
  name?: string | null;
};
export type sys_provider_types_on_conflict = {
  constraint: sys_provider_types_constraint;
  update_columns: ReadonlyArray<sys_provider_types_update_column>;
  where?: sys_provider_types_bool_exp | null;
};
export type sys_providers_properties_arr_rel_insert_input = {
  data: ReadonlyArray<sys_providers_properties_insert_input>;
  on_conflict?: sys_providers_properties_on_conflict | null;
};
export type sys_providers_properties_insert_input = {
  created_at_datetime?: string | null;
  inactivated_at_datetime?: string | null;
  organization_id?: number | null;
  property?: sys_properties_obj_rel_insert_input | null;
  property_id?: number | null;
  provider?: sys_providers_obj_rel_insert_input | null;
  provider_id?: number | null;
  provider_property_id?: number | null;
  updated_at_datetime?: string | null;
};
export type sys_providers_properties_on_conflict = {
  constraint: sys_providers_properties_constraint;
  update_columns: ReadonlyArray<sys_providers_properties_update_column>;
  where?: sys_providers_properties_bool_exp | null;
};
export type sys_users_arr_rel_insert_input = {
  data: ReadonlyArray<sys_users_insert_input>;
  on_conflict?: sys_users_on_conflict | null;
};
export type sys_users_on_conflict = {
  constraint: sys_users_constraint;
  update_columns: ReadonlyArray<sys_users_update_column>;
  where?: sys_users_bool_exp | null;
};
export type sys_providers_on_conflict = {
  constraint: sys_providers_constraint;
  update_columns: ReadonlyArray<sys_providers_update_column>;
  where?: sys_providers_bool_exp | null;
};
export type sys_submission_types_on_conflict = {
  constraint: sys_submission_types_constraint;
  update_columns: ReadonlyArray<sys_submission_types_update_column>;
  where?: sys_submission_types_bool_exp | null;
};
export type sys_properties_roles_users_on_conflict = {
  constraint: sys_properties_roles_users_constraint;
  update_columns: ReadonlyArray<sys_properties_roles_users_update_column>;
  where?: sys_properties_roles_users_bool_exp | null;
};
export type sakura_user_obj_rel_insert_input = {
  data: sakura_user_insert_input;
};
export type sakura_user_insert_input = {
  attributes?: any | null;
  auth0_synced?: boolean | null;
  create_time?: string | null;
  dashboards?: any | null;
  defaultPowerBiReportId?: string | null;
  disabled?: boolean | null;
  email?: string | null;
  first_name?: string | null;
  id?: number | null;
  last_modified?: string | null;
  last_name?: string | null;
  last_seen?: sakura_users_last_seen_obj_rel_insert_input | null;
  mongo_id?: string | null;
  organization_id?: number | null;
  role?: string | null;
  sakura_organization?: sakura_organization_obj_rel_insert_input | null;
  sakura_user_permissions?: sakura_user_permission_arr_rel_insert_input | null;
  sys_user?: sys_users_obj_rel_insert_input | null;
};
export type sakura_users_last_seen_obj_rel_insert_input = {
  data: sakura_users_last_seen_insert_input;
};
export type sakura_users_last_seen_insert_input = {
  attributes?: any | null;
  auth0_synced?: boolean | null;
  create_time?: string | null;
  dashboards?: any | null;
  disabled?: boolean | null;
  email?: string | null;
  email_ci?: any | null;
  first_name?: string | null;
  id?: number | null;
  last_login_at?: string | null;
  last_modified?: string | null;
  last_name?: string | null;
  last_seen?: string | null;
  mongo_id?: string | null;
  organization_id?: number | null;
  role?: string | null;
};
export type sakura_organization_obj_rel_insert_input = {
  data: sakura_organization_insert_input;
};
export type sakura_organization_insert_input = {
  additional_props?: any | null;
  api_hasura_role?: string | null;
  authz_version_id?: number | null;
  create_time?: string | null;
  dashboards?: any | null;
  description?: string | null;
  disabled?: boolean | null;
  domOwner?: string | null;
  hasura_access_token?: string | null;
  hasura_org_name?: string | null;
  hasura_role?: string | null;
  id?: number | null;
  image_url?: string | null;
  last_modified?: string | null;
  name?: string | null;
  organization_permissions?: sakura_organization_permission_arr_rel_insert_input | null;
  plan_expires_at?: string | null;
  plan_type?: string | null;
  powerbi_api_url?: string | null;
  powerbi_client_id?: string | null;
  powerbi_client_secret?: string | null;
  powerbi_tenant_id?: string | null;
  publish_on_website?: boolean | null;
  salesforce_id?: string | null;
  slug?: string | null;
  sys_organization?: sys_organizations_obj_rel_insert_input | null;
  theme_name?: string | null;
  website?: string | null;
  website_image_url?: string | null;
};
export type sakura_organization_permission_arr_rel_insert_input = {
  data: ReadonlyArray<sakura_organization_permission_insert_input>;
};
export type sakura_organization_permission_insert_input = {
  id?: number | null;
  name?: string | null;
  organization_id?: number | null;
  sakura_organization?: sakura_organization_obj_rel_insert_input | null;
  sys_organization?: sys_organizations_obj_rel_insert_input | null;
};
export type sakura_user_permission_arr_rel_insert_input = {
  data: ReadonlyArray<sakura_user_permission_insert_input>;
};
export type sakura_user_permission_insert_input = {
  id?: number | null;
  name?: string | null;
  user_id?: number | null;
};
export type sys_property_batch_stage_transitions_on_conflict = {
  constraint: sys_property_batch_stage_transitions_constraint;
  update_columns: ReadonlyArray<sys_property_batch_stage_transitions_update_column>;
  where?: sys_property_batch_stage_transitions_bool_exp | null;
};
export type sys_property_batch_stages_on_conflict = {
  constraint: sys_property_batch_stages_constraint;
  update_columns: ReadonlyArray<sys_property_batch_stages_update_column>;
  where?: sys_property_batch_stages_bool_exp | null;
};
export type sys_property_batch_package_reports_on_conflict = {
  constraint: sys_property_batch_package_reports_constraint;
  update_columns: ReadonlyArray<sys_property_batch_package_reports_update_column>;
  where?: sys_property_batch_package_reports_bool_exp | null;
};
export type sys_package_reports_on_conflict = {
  constraint: sys_package_reports_constraint;
  update_columns: ReadonlyArray<sys_package_reports_update_column>;
  where?: sys_package_reports_bool_exp | null;
};
export type sys_comments_on_conflict = {
  constraint: sys_comments_constraint;
  update_columns: ReadonlyArray<sys_comments_update_column>;
  where?: sys_comments_bool_exp | null;
};
export type sys_property_batch_datasets_arr_rel_insert_input = {
  data: ReadonlyArray<sys_property_batch_datasets_insert_input>;
  on_conflict?: sys_property_batch_datasets_on_conflict | null;
};
export type sys_property_batch_datasets_insert_input = {
  approved_datetime?: string | null;
  batch_file?: sys_batch_files_obj_rel_insert_input | null;
  batch_file_by_dataset_id_file_id?: sys_batch_files_obj_rel_insert_input | null;
  created_at_datetime?: string | null;
  dataset?: sys_datasets_obj_rel_insert_input | null;
  dataset_id?: number | null;
  dataset_row_count?: number | null;
  dataset_status?: sys_dataset_statuses_obj_rel_insert_input | null;
  dataset_status_id?: number | null;
  file_id?: number | null;
  is_approved?: boolean | null;
  is_valid?: boolean | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  property_batch?: sys_property_batches_obj_rel_insert_input | null;
  property_batch_dataset_id?: number | null;
  property_batch_dataset_validations?: sys_property_batch_dataset_validations_arr_rel_insert_input | null;
  property_batch_id?: number | null;
  updated_at_datetime?: string | null;
  validated_datetime?: string | null;
};
export type sys_batch_files_obj_rel_insert_input = {
  data: sys_batch_files_insert_input;
  on_conflict?: sys_batch_files_on_conflict | null;
};
export type sys_batch_files_on_conflict = {
  constraint: sys_batch_files_constraint;
  update_columns: ReadonlyArray<sys_batch_files_update_column>;
  where?: sys_batch_files_bool_exp | null;
};
export type sys_dataset_statuses_obj_rel_insert_input = {
  data: sys_dataset_statuses_insert_input;
  on_conflict?: sys_dataset_statuses_on_conflict | null;
};
export type sys_dataset_statuses_insert_input = {
  created_at_datetime?: string | null;
  dataset_status_description?: string | null;
  dataset_status_id?: number | null;
  property_batch_datasets?: sys_property_batch_datasets_arr_rel_insert_input | null;
  updated_at_datetime?: string | null;
};
export type sys_dataset_statuses_on_conflict = {
  constraint: sys_dataset_statuses_constraint;
  update_columns: ReadonlyArray<sys_dataset_statuses_update_column>;
  where?: sys_dataset_statuses_bool_exp | null;
};
export type sys_property_batch_dataset_validations_arr_rel_insert_input = {
  data: ReadonlyArray<sys_property_batch_dataset_validations_insert_input>;
  on_conflict?: sys_property_batch_dataset_validations_on_conflict | null;
};
export type sys_property_batch_dataset_validations_insert_input = {
  dataset_validation_rule?: sys_dataset_validation_rules_obj_rel_insert_input | null;
  dataset_validation_rule_id?: number | null;
  is_valid?: boolean | null;
  organization_id?: number | null;
  property_batch_dataset?: sys_property_batch_datasets_obj_rel_insert_input | null;
  property_batch_dataset_id?: number | null;
  property_batch_dataset_validation_id?: number | null;
  validated_datetime?: string | null;
  validation_mode?: string | null;
  validation_params?: any | null;
};
export type sys_property_batch_datasets_obj_rel_insert_input = {
  data: sys_property_batch_datasets_insert_input;
  on_conflict?: sys_property_batch_datasets_on_conflict | null;
};
export type sys_property_batch_datasets_on_conflict = {
  constraint: sys_property_batch_datasets_constraint;
  update_columns: ReadonlyArray<sys_property_batch_datasets_update_column>;
  where?: sys_property_batch_datasets_bool_exp | null;
};
export type sys_property_batch_dataset_validations_on_conflict = {
  constraint: sys_property_batch_dataset_validations_constraint;
  update_columns: ReadonlyArray<sys_property_batch_dataset_validations_update_column>;
  where?: sys_property_batch_dataset_validations_bool_exp | null;
};
export type sys_property_batches_monitoring_obj_rel_insert_input = {
  data: sys_property_batches_monitoring_insert_input;
};
export type sys_property_batches_monitoring_insert_input = {
  batch_status?: string | null;
  batch_validations_failed?: number | null;
  batch_validations_total?: number | null;
  dataset_validations_failed?: number | null;
  dataset_validations_total?: number | null;
  datasets_total?: number | null;
  datasets_uploaded?: number | null;
  datasets_uploaded_ratio?: number | null;
  organization_id?: number | null;
  property_batch?: sys_property_batches_obj_rel_insert_input | null;
  property_batch_id?: number | null;
  submission_status?: string | null;
  validations_failed?: number | null;
  validations_total?: number | null;
};
export type sys_reminders_arr_rel_insert_input = {
  data: ReadonlyArray<sys_reminders_insert_input>;
  on_conflict?: sys_reminders_on_conflict | null;
};
export type sys_reminders_insert_input = {
  author_id?: number | null;
  created_at_datetime?: string | null;
  is_resolved?: boolean | null;
  organization_id?: number | null;
  package_report?: sys_package_reports_obj_rel_insert_input | null;
  package_report_id?: number | null;
  property?: sys_properties_obj_rel_insert_input | null;
  property_batch?: sys_property_batches_obj_rel_insert_input | null;
  property_batch_id?: number | null;
  property_id?: number | null;
  reminder_id?: number | null;
  reminders_reviewed?: sys_reminders_reviewed_arr_rel_insert_input | null;
  submission?: sys_submissions_obj_rel_insert_input | null;
  submission_id?: number | null;
  text?: string | null;
  user?: sys_users_obj_rel_insert_input | null;
};
export type sys_reminders_reviewed_arr_rel_insert_input = {
  data: ReadonlyArray<sys_reminders_reviewed_insert_input>;
  on_conflict?: sys_reminders_reviewed_on_conflict | null;
};
export type sys_reminders_reviewed_insert_input = {
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  reminder?: sys_reminders_obj_rel_insert_input | null;
  reminder_id?: number | null;
  reminders_reviewed_id?: number | null;
  reviewed_at_datetime?: string | null;
  user?: sys_users_obj_rel_insert_input | null;
  user_id?: number | null;
};
export type sys_reminders_obj_rel_insert_input = {
  data: sys_reminders_insert_input;
  on_conflict?: sys_reminders_on_conflict | null;
};
export type sys_reminders_on_conflict = {
  constraint: sys_reminders_constraint;
  update_columns: ReadonlyArray<sys_reminders_update_column>;
  where?: sys_reminders_bool_exp | null;
};
export type sys_reminders_reviewed_on_conflict = {
  constraint: sys_reminders_reviewed_constraint;
  update_columns: ReadonlyArray<sys_reminders_reviewed_update_column>;
  where?: sys_reminders_reviewed_bool_exp | null;
};
export type sys_property_batch_validations_on_conflict = {
  constraint: sys_property_batch_validations_constraint;
  update_columns: ReadonlyArray<sys_property_batch_validations_update_column>;
  where?: sys_property_batch_validations_bool_exp | null;
};
export type sys_properties_datasets_arr_rel_insert_input = {
  data: ReadonlyArray<sys_properties_datasets_insert_input>;
  on_conflict?: sys_properties_datasets_on_conflict | null;
};
export type sys_properties_datasets_insert_input = {
  dataset?: sys_datasets_obj_rel_insert_input | null;
  dataset_id?: number | null;
  is_active?: boolean | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  property?: sys_properties_obj_rel_insert_input | null;
  property_dataset_id?: number | null;
  property_id?: number | null;
  template_id?: string | null;
};
export type sys_properties_datasets_on_conflict = {
  constraint: sys_properties_datasets_constraint;
  update_columns: ReadonlyArray<sys_properties_datasets_update_column>;
  where?: sys_properties_datasets_bool_exp | null;
};
export type sys_properties_mapping_sets_arr_rel_insert_input = {
  data: ReadonlyArray<sys_properties_mapping_sets_insert_input>;
  on_conflict?: sys_properties_mapping_sets_on_conflict | null;
};
export type sys_properties_mapping_sets_on_conflict = {
  constraint: sys_properties_mapping_sets_constraint;
  update_columns: ReadonlyArray<sys_properties_mapping_sets_update_column>;
  where?: sys_properties_mapping_sets_bool_exp | null;
};
export type sys_property_models_obj_rel_insert_input = {
  data: sys_property_models_insert_input;
  on_conflict?: sys_property_models_on_conflict | null;
};
export type sys_property_models_insert_input = {
  created_at_datetime?: string | null;
  inactivated_at_datetime?: string | null;
  organizations?: sys_organizations_arr_rel_insert_input | null;
  property_model_id?: number | null;
  property_model_slug?: string | null;
  property_models_types?: sys_property_models_types_arr_rel_insert_input | null;
  updated_at_datetime?: string | null;
};
export type sys_organizations_arr_rel_insert_input = {
  data: ReadonlyArray<sys_organizations_insert_input>;
  on_conflict?: sys_organizations_on_conflict | null;
};
export type sys_organizations_on_conflict = {
  constraint: sys_organizations_constraint;
  update_columns: ReadonlyArray<sys_organizations_update_column>;
  where?: sys_organizations_bool_exp | null;
};
export type sys_property_models_types_arr_rel_insert_input = {
  data: ReadonlyArray<sys_property_models_types_insert_input>;
  on_conflict?: sys_property_models_types_on_conflict | null;
};
export type sys_property_models_types_insert_input = {
  approved_property_batch_stage?: sys_property_batch_stages_obj_rel_insert_input | null;
  approved_property_batch_stage_id?: number | null;
  created_at_datetime?: string | null;
  inactivated_at_datetime?: string | null;
  initial_property_batch_stage?: sys_property_batch_stages_obj_rel_insert_input | null;
  initial_property_batch_stage_id?: number | null;
  property_model?: sys_property_models_obj_rel_insert_input | null;
  property_model_id?: number | null;
  property_model_type_id?: number | null;
  property_type?: sys_property_types_obj_rel_insert_input | null;
  property_type_id?: number | null;
  updated_at_datetime?: string | null;
};
export type sys_property_types_obj_rel_insert_input = {
  data: sys_property_types_insert_input;
  on_conflict?: sys_property_types_on_conflict | null;
};
export type sys_property_types_insert_input = {
  created_at_datetime?: string | null;
  inactivated_at_datetime?: string | null;
  properties?: sys_properties_arr_rel_insert_input | null;
  property_models_types?: sys_property_models_types_arr_rel_insert_input | null;
  property_type_id?: number | null;
  property_type_slug?: string | null;
  updated_at_datetime?: string | null;
};
export type sys_property_types_on_conflict = {
  constraint: sys_property_types_constraint;
  update_columns: ReadonlyArray<sys_property_types_update_column>;
  where?: sys_property_types_bool_exp | null;
};
export type sys_property_models_types_on_conflict = {
  constraint: sys_property_models_types_constraint;
  update_columns: ReadonlyArray<sys_property_models_types_update_column>;
  where?: sys_property_models_types_bool_exp | null;
};
export type sys_property_models_on_conflict = {
  constraint: sys_property_models_constraint;
  update_columns: ReadonlyArray<sys_property_models_update_column>;
  where?: sys_property_models_bool_exp | null;
};
export type sys_property_relationships_on_conflict = {
  constraint: sys_property_relationships_constraint;
  update_columns: ReadonlyArray<sys_property_relationships_update_column>;
  where?: sys_property_relationships_bool_exp | null;
};
export type sys_properties_dataset_validation_rules_on_conflict = {
  constraint: sys_properties_dataset_validation_rules_constraint;
  update_columns: ReadonlyArray<sys_properties_dataset_validation_rules_update_column>;
  where?: sys_properties_dataset_validation_rules_bool_exp | null;
};
export type sys_mapping_fields_arr_rel_insert_input = {
  data: ReadonlyArray<sys_mapping_fields_insert_input>;
  on_conflict?: sys_mapping_fields_on_conflict | null;
};
export type sys_mapping_fields_on_conflict = {
  constraint: sys_mapping_fields_constraint;
  update_columns: ReadonlyArray<sys_mapping_fields_update_column>;
  where?: sys_mapping_fields_bool_exp | null;
};
export type sys_organization_account_tree_details_arr_rel_insert_input = {
  data: ReadonlyArray<sys_organization_account_tree_details_insert_input>;
  on_conflict?: sys_organization_account_tree_details_on_conflict | null;
};
export type sys_organization_account_tree_details_insert_input = {
  account_code_description?: string | null;
  account_group_code?: string | null;
  account_tree_id?: number | null;
  account_type?: string | null;
  chart_of_account_id?: number | null;
  created_at_datetime?: string | null;
  gl_account_code?: string | null;
  indent?: number | null;
  is_contiguous?: boolean | null;
  is_hidden?: boolean | null;
  is_negate?: boolean | null;
  is_normal_balance?: boolean | null;
  nest_level?: number | null;
  order_index?: number | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_account_tree?: sys_organization_account_trees_obj_rel_insert_input | null;
  organization_account_tree_details?: sys_organization_account_tree_details_arr_rel_insert_input | null;
  organization_chart_of_account?: sys_organization_account_tree_details_obj_rel_insert_input | null;
  organization_id?: number | null;
  parent_chart_of_account_id?: number | null;
  total_into_code?: string | null;
  tree_path?: string | null;
  updated_at_datetime?: string | null;
};
export type sys_organization_account_trees_obj_rel_insert_input = {
  data: sys_organization_account_trees_insert_input;
  on_conflict?: sys_organization_account_trees_on_conflict | null;
};
export type sys_organization_account_trees_insert_input = {
  account_tree_name?: string | null;
  created_at_datetime?: string | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_account_tree_details?: sys_organization_account_tree_details_arr_rel_insert_input | null;
  organization_account_tree_id?: number | null;
  organization_id?: number | null;
  updated_at_datetime?: string | null;
};
export type sys_organization_account_trees_on_conflict = {
  constraint: sys_organization_account_trees_constraint;
  update_columns: ReadonlyArray<sys_organization_account_trees_update_column>;
  where?: sys_organization_account_trees_bool_exp | null;
};
export type sys_organization_account_tree_details_obj_rel_insert_input = {
  data: sys_organization_account_tree_details_insert_input;
  on_conflict?: sys_organization_account_tree_details_on_conflict | null;
};
export type sys_organization_account_tree_details_on_conflict = {
  constraint: sys_organization_account_tree_details_constraint;
  update_columns: ReadonlyArray<sys_organization_account_tree_details_update_column>;
  where?: sys_organization_account_tree_details_bool_exp | null;
};
export type sys_providers_arr_rel_insert_input = {
  data: ReadonlyArray<sys_providers_insert_input>;
  on_conflict?: sys_providers_on_conflict | null;
};
export type sakura_user_arr_rel_insert_input = {
  data: ReadonlyArray<sakura_user_insert_input>;
};
export type sys_submission_types_arr_rel_insert_input = {
  data: ReadonlyArray<sys_submission_types_insert_input>;
  on_conflict?: sys_submission_types_on_conflict | null;
};
export type sys_mapping_set_values_arr_rel_insert_input = {
  data: ReadonlyArray<sys_mapping_set_values_insert_input>;
  on_conflict?: sys_mapping_set_values_on_conflict | null;
};
export type sys_mapping_set_values_insert_input = {
  created_at_datetime?: string | null;
  custom_attributes?: any | null;
  is_active?: boolean | null;
  mapping_set?: sys_mapping_sets_obj_rel_insert_input | null;
  mapping_set_id?: number | null;
  mapping_set_value_id?: number | null;
  organization_id?: number | null;
  source_mapping_code?: string | null;
  source_mapping_label?: string | null;
  target_mapping_code?: string | null;
  target_mapping_label?: string | null;
  updated_at_datetime?: string | null;
};
export type sys_mapping_set_values_on_conflict = {
  constraint: sys_mapping_set_values_constraint;
  update_columns: ReadonlyArray<sys_mapping_set_values_update_column>;
  where?: sys_mapping_set_values_bool_exp | null;
};
export type sys_organization_master_chart_of_accounts_obj_rel_insert_input = {
  data: sys_organization_master_chart_of_accounts_insert_input;
  on_conflict?: sys_organization_master_chart_of_accounts_on_conflict | null;
};
export type sys_organization_master_chart_of_accounts_insert_input = {
  chart_of_accounts_description?: string | null;
  chart_of_accounts_name?: string | null;
  created_at_datetime?: string | null;
  is_active?: boolean | null;
  master_chart_of_account_id?: number | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  organization_master_chart_of_account_values?: sys_organization_master_chart_of_account_values_arr_rel_insert_input | null;
  updated_at_datetime?: string | null;
};
export type sys_organization_master_chart_of_account_values_arr_rel_insert_input = {
  data: ReadonlyArray<sys_organization_master_chart_of_account_values_insert_input>;
  on_conflict?: sys_organization_master_chart_of_account_values_on_conflict | null;
};
export type sys_organization_master_chart_of_account_values_insert_input = {
  account_code?: string | null;
  account_hold?: boolean | null;
  account_name?: string | null;
  created_at_datetime?: string | null;
  is_active?: boolean | null;
  master_chart_of_account_id?: number | null;
  master_chart_of_account_values_id?: number | null;
  organization?: sys_organizations_obj_rel_insert_input | null;
  organization_id?: number | null;
  organization_master_chart_of_accounts?: sys_organization_master_chart_of_accounts_obj_rel_insert_input | null;
  updated_at_datetime?: string | null;
};
export type sys_organization_master_chart_of_account_values_on_conflict = {
  constraint: sys_organization_master_chart_of_account_values_constraint;
  update_columns: ReadonlyArray<sys_organization_master_chart_of_account_values_update_column>;
  where?: sys_organization_master_chart_of_account_values_bool_exp | null;
};
export type sys_organization_master_chart_of_accounts_on_conflict = {
  constraint: sys_organization_master_chart_of_accounts_constraint;
  update_columns: ReadonlyArray<sys_organization_master_chart_of_accounts_update_column>;
  where?: sys_organization_master_chart_of_accounts_bool_exp | null;
};
export type AssignMappingDialogMutation$variables = {
  mapping_field_id?: number | null;
  objects: ReadonlyArray<sys_properties_mapping_sets_insert_input>;
  property_ids?: ReadonlyArray<number> | null;
};
export type AssignMappingDialogMutation$data = {
  readonly insert_sys_properties_mapping_sets: {
    readonly affected_rows: number;
  } | null;
  readonly update_sys_properties_mapping_sets: {
    readonly affected_rows: number;
  } | null;
};
export type AssignMappingDialogMutation = {
  response: AssignMappingDialogMutation$data;
  variables: AssignMappingDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mapping_field_id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objects"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "property_ids"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "_set",
        "value": {
          "is_active": false
        }
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "mapping_field_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "mapping_field_id"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "property_ids"
              }
            ],
            "kind": "ObjectValue",
            "name": "property_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties_mapping_sets_mutation_response",
    "kind": "LinkedField",
    "name": "update_sys_properties_mapping_sets",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      }
    ],
    "concreteType": "sys_properties_mapping_sets_mutation_response",
    "kind": "LinkedField",
    "name": "insert_sys_properties_mapping_sets",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignMappingDialogMutation",
    "selections": (v4/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AssignMappingDialogMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "6a1356ca1ba2c1ec47320c8d2b10351d",
    "id": null,
    "metadata": {},
    "name": "AssignMappingDialogMutation",
    "operationKind": "mutation",
    "text": "mutation AssignMappingDialogMutation(\n  $objects: [sys_properties_mapping_sets_insert_input!]!\n  $property_ids: [Int!]\n  $mapping_field_id: Int\n) {\n  update_sys_properties_mapping_sets(where: {mapping_field_id: {_eq: $mapping_field_id}, property_id: {_in: $property_ids}}, _set: {is_active: false}) {\n    affected_rows\n  }\n  insert_sys_properties_mapping_sets(objects: $objects) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "851185903a96e6698b8c5d612f1e2f72";

export default node;
