/**
 * @generated SignedSource<<ef235fb742c359aef4cb9af2b49bac74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type recoilgetSubmissionUsersQuery$variables = {
  property_id?: number | null;
};
export type recoilgetSubmissionUsersQuery$data = {
  readonly preparers: ReadonlyArray<{
    readonly property_role_user_id: number;
    readonly user: {
      readonly sakura_user: {
        readonly email: string;
        readonly first_name: string | null;
        readonly last_name: string | null;
      } | null;
    };
  }>;
  readonly reviewers_1: ReadonlyArray<{
    readonly property_role_user_id: number;
    readonly user: {
      readonly sakura_user: {
        readonly email: string;
        readonly first_name: string | null;
        readonly last_name: string | null;
      } | null;
    };
  }>;
  readonly reviewers_2: ReadonlyArray<{
    readonly property_role_user_id: number;
    readonly user: {
      readonly sakura_user: {
        readonly email: string;
        readonly first_name: string | null;
        readonly last_name: string | null;
      } | null;
    };
  }>;
  readonly reviewers_3: ReadonlyArray<{
    readonly property_role_user_id: number;
    readonly user: {
      readonly sakura_user: {
        readonly email: string;
        readonly first_name: string | null;
        readonly last_name: string | null;
      } | null;
    };
  }>;
};
export type recoilgetSubmissionUsersQuery = {
  response: recoilgetSubmissionUsersQuery$data;
  variables: recoilgetSubmissionUsersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "property_id"
  }
],
v1 = {
  "kind": "Literal",
  "name": "order_by",
  "value": {
    "user": {
      "sakura_user": {
        "first_name": "asc"
      }
    }
  }
},
v2 = {
  "kind": "Literal",
  "name": "is_active",
  "value": {
    "_eq": true
  }
},
v3 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "_eq",
      "variableName": "property_id"
    }
  ],
  "kind": "ObjectValue",
  "name": "property_id"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "property_role_user_id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "sys_users",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "sakura_user",
        "kind": "LinkedField",
        "name": "sakura_user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "first_name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "last_name",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = [
  {
    "alias": "preparers",
    "args": [
      (v1/*: any*/),
      {
        "fields": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "Literal",
            "name": "property_role",
            "value": {
              "property_role_slug": {
                "_eq": "preparer"
              }
            }
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties_roles_users",
    "kind": "LinkedField",
    "name": "sys_properties_roles_users",
    "plural": true,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": "reviewers_1",
    "args": [
      (v1/*: any*/),
      {
        "fields": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "Literal",
            "name": "property_role",
            "value": {
              "property_role_slug": {
                "_eq": "reviewer_1"
              }
            }
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties_roles_users",
    "kind": "LinkedField",
    "name": "sys_properties_roles_users",
    "plural": true,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": "reviewers_2",
    "args": [
      (v1/*: any*/),
      {
        "fields": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "Literal",
            "name": "property_role",
            "value": {
              "property_role_slug": {
                "_eq": "reviewer_2"
              }
            }
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties_roles_users",
    "kind": "LinkedField",
    "name": "sys_properties_roles_users",
    "plural": true,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": "reviewers_3",
    "args": [
      (v1/*: any*/),
      {
        "fields": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "Literal",
            "name": "property_role",
            "value": {
              "property_role_slug": {
                "_eq": "reviewer_3"
              }
            }
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "sys_properties_roles_users",
    "kind": "LinkedField",
    "name": "sys_properties_roles_users",
    "plural": true,
    "selections": (v4/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recoilgetSubmissionUsersQuery",
    "selections": (v5/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recoilgetSubmissionUsersQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "033455064ac6c69e43049616fa2f6359",
    "id": null,
    "metadata": {},
    "name": "recoilgetSubmissionUsersQuery",
    "operationKind": "query",
    "text": "query recoilgetSubmissionUsersQuery(\n  $property_id: Int\n) {\n  preparers: sys_properties_roles_users(where: {property_id: {_eq: $property_id}, is_active: {_eq: true}, property_role: {property_role_slug: {_eq: \"preparer\"}}}, order_by: {user: {sakura_user: {first_name: asc}}}) {\n    property_role_user_id\n    user {\n      sakura_user {\n        email\n        first_name\n        last_name\n      }\n    }\n  }\n  reviewers_1: sys_properties_roles_users(where: {property_id: {_eq: $property_id}, is_active: {_eq: true}, property_role: {property_role_slug: {_eq: \"reviewer_1\"}}}, order_by: {user: {sakura_user: {first_name: asc}}}) {\n    property_role_user_id\n    user {\n      sakura_user {\n        email\n        first_name\n        last_name\n      }\n    }\n  }\n  reviewers_2: sys_properties_roles_users(where: {property_id: {_eq: $property_id}, is_active: {_eq: true}, property_role: {property_role_slug: {_eq: \"reviewer_2\"}}}, order_by: {user: {sakura_user: {first_name: asc}}}) {\n    property_role_user_id\n    user {\n      sakura_user {\n        email\n        first_name\n        last_name\n      }\n    }\n  }\n  reviewers_3: sys_properties_roles_users(where: {property_id: {_eq: $property_id}, is_active: {_eq: true}, property_role: {property_role_slug: {_eq: \"reviewer_3\"}}}, order_by: {user: {sakura_user: {first_name: asc}}}) {\n    property_role_user_id\n    user {\n      sakura_user {\n        email\n        first_name\n        last_name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "762573b049339240c79f89503154a451";

export default node;
