import {
  QueryResult,
  useCherreSetState,
  useCherreState,
  useCherreValue,
} from '@cherre-frontend/data-fetching';
import {
  ActionsMenu,
  Grid,
  MRT_ColumnDef,
  MRT_Row,
  MenuItem,
  Link as MuiLink,
  Panel,
  Table,
  Typography,
  styled,
  tableBaseStyle,
  useTable,
  useTheme,
} from '@cherre-frontend/ui';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { NoItemsFound } from 'src/products/data-submission-portal/components/NoBatchesFound';
import { getSubmissionPeriod } from 'src/products/data-submission-portal/utils/getSubmissionPeriod';
import { ProviderChip } from '../../../batch-management/Panels/SubmissionsPanel/components/ProviderChip';
import {
  getPropertyBatches,
  getPropertyBatchesTotal,
  monitorSubmissionsTableState,
  submissionOverviewDialogPropertyBatchIdState,
  submissionOverviewDialogState,
} from '../../recoil';
import { getStageLabelFromId } from '../../utils';
import { SubmissionBatchDueDateStatusChip } from './components/SubmissionBatchDueDateStatus';
import {
  StatusCode,
  SubmissionBatchStatusChip,
} from './components/SubmissionBatchStatus';

export const showSubmissionDetailsLink = (
  submission_id?: number,
  property_batch_id?: number
) => {
  const searchParams = property_batch_id
    ? encodeURIComponent(
        btoa(
          JSON.stringify({
            expandedCards: [property_batch_id],
          })
        )
      )
    : '';

  return `/dsp/view_admin/submissions/${submission_id}?${searchParams}`;
};

const PanelStyled = styled(Panel)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const MonitorSubmissionsTable: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();

  const [tableState, setTableState] = useCherreState(
    monitorSubmissionsTableState
  );

  const propertyBatches = useCherreValue(getPropertyBatches());
  const propertyBatchesTotal = useCherreValue(getPropertyBatchesTotal());

  const setId = useCherreSetState(submissionOverviewDialogPropertyBatchIdState);
  const setOverviewDialog = useCherreSetState(submissionOverviewDialogState);

  const rowActionOptions = [
    {
      name: 'view-overview',
      label: <span>Submission Summary</span>,
      onClick: (
        row: MRT_Row<QueryResult<typeof getPropertyBatches>[number]>
      ) => {
        setId(row.original.property_batch_id);
      },
    },
    {
      name: 'view-details',
      label: <span>Submission Batch Detail</span>,
      onClick: (
        row: MRT_Row<QueryResult<typeof getPropertyBatches>[number]>
      ) => {
        const link = showSubmissionDetailsLink(
          row.original.property_batch?.submission.submission_id,
          row.original.property_batch?.property_batch_id
        );

        history.push(link);
      },
    },
    {
      name: 'contact-users',
      label: <span>Contact Submission Users</span>,
      onClick: (
        row: MRT_Row<QueryResult<typeof getPropertyBatches>[number]>
      ) => {
        setOverviewDialog({
          propertyBatchId: row.original.property_batch_id,
          submissionId: row.original.property_batch.submission.submission_id,
          tab: 2,
        });
      },
    },
  ];

  const columns: MRT_ColumnDef<
    QueryResult<typeof getPropertyBatches>[number]
  >[] = useMemo(
    () => [
      {
        accessorFn: (row) => row.property_batch?.property?.property_code,
        id: 'property_batch.property.property_code',
        header: 'Target ID',
        size: 150,
      },
      {
        accessorKey: 'property_batch.properties_flattened_union.entity_id',
        header: 'Source ID',
        size: 100,
        Cell: ({ renderedCellValue, row }) => (
          <MuiLink
            component={Link}
            underline='hover'
            to={showSubmissionDetailsLink(
              row.original.property_batch?.submission.submission_id,
              row.original.property_batch?.property_batch_id
            )}
          >
            {renderedCellValue}
          </MuiLink>
        ),
      },
      {
        accessorFn: (row) =>
          row.property_batch?.properties_flattened_union?.property_mapping ||
          '1:1',
        id: 'property_batch.properties_flattened_union.property_mapping',
        header: 'Mapping',
        size: 100,
      },
      {
        accessorFn: (row) =>
          row.property_batch?.property?.property_name
            ? row.property_batch?.property?.property_name
            : row.property_batch?.property?.address,
        id: 'property_batch.property.property_name',
        header: 'Name',
        size: 230,
      },
      {
        accessorKey:
          'property_batch.submission.submission_type.submission_type_label',
        header: 'Type',
        size: 140,
      },
      {
        accessorFn: (row) => row.property_batch?.property?.fund,
        id: 'property_batch.property.fund',
        header: 'Fund',
        size: 140,
      },
      {
        accessorKey: 'property_batch.submission.provider.provider_name',
        header: 'Provider',
        size: 200,
        Cell: ({ row }) => {
          return (
            <ProviderChip
              label={
                row.original.property_batch?.submission.provider.provider_name
              }
            />
          );
        },
      },
      {
        accessorKey: 'property_batch.property.users.aggregate.count',
        id: 'property_batch.property.properties_roles_users_aggregate.count',
        header: 'Users',
        size: 120,
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => (
          <MuiLink
            href='#'
            underline='hover'
            onClick={() =>
              setOverviewDialog({
                propertyBatchId: row.original.property_batch_id,
                submissionId:
                  row.original.property_batch.submission.submission_id,
                tab: 2,
              })
            }
          >
            <Typography>
              {row.original.property_batch?.property.users.aggregate?.count ??
                0}
            </Typography>
          </MuiLink>
        ),
      },
      {
        accessorKey: 'datasets_uploaded_ratio',
        header: 'Uploaded Datasets',
        size: 50,
        muiTableHeadCellProps: ({ column, table }) => ({
          align: 'right',
          sx: {
            ...tableBaseStyle.muiTableHeadCellProps({ column, table }).sx,
            '& .Mui-TableHeadCell-Content-Wrapper': {
              textOverflow: 'unset',
              whiteSpace: 'unset',
            },
          },
        }),
        muiTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          return (
            <MuiLink
              underline='hover'
              href='#'
              onClick={() => {
                setOverviewDialog({
                  propertyBatchId: row.original.property_batch_id,
                  submissionId:
                    row.original.property_batch.submission.submission_id,
                  tab: 0,
                });
              }}
            >
              <Typography>
                {row.original.datasets_uploaded}/{row.original.datasets_total}
              </Typography>
            </MuiLink>
          );
        },
      },
      {
        accessorKey: 'validations_failed',
        id: 'validations_failed',
        header: 'Failed Validations',
        size: 50,
        muiTableHeadCellProps: ({ column, table }) => ({
          align: 'right',
          sx: {
            ...tableBaseStyle.muiTableHeadCellProps({ column, table }).sx,
            '& .Mui-TableHeadCell-Content-Wrapper': {
              textOverflow: 'unset',
              whiteSpace: 'unset',
            },
          },
        }),
        muiTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          return (
            <MuiLink
              href='#'
              underline='hover'
              onClick={() => {
                setOverviewDialog({
                  propertyBatchId: row.original.property_batch_id,
                  submissionId:
                    row.original.property_batch.submission.submission_id,
                  tab: 0,
                });
              }}
            >
              <Typography>{row.original.validations_failed}</Typography>
            </MuiLink>
          );
        },
      },
      {
        accessorKey: 'submission_status',
        header: 'Submission Status',
        size: 50,
        muiTableHeadCellProps: ({ column, table }) => ({
          align: 'left',
          sx: {
            ...tableBaseStyle.muiTableHeadCellProps({ column, table }).sx,
            '& .Mui-TableHeadCell-Content-Wrapper': {
              textOverflow: 'unset',
              whiteSpace: 'unset',
            },
          },
        }),
        Cell: ({ row }) => (
          <SubmissionBatchStatusChip
            statusCode={
              (row.original.submission_status ?? 'Not Started') as StatusCode
            }
          />
        ),
      },
      {
        header: 'Stage',
        accessorKey: 'property_batch.property_batch_stage_id',
        accessorFn: (row) =>
          getStageLabelFromId(row.property_batch?.property_batch_stage_id ?? 0),
        size: 120,
      },
      {
        header: 'Due Date',
        accessorKey: 'property_batch.submission.due_date',
        accessorFn: (row) =>
          moment(row.property_batch?.submission.due_date).format('MMM D, YYYY'),
        size: 120,
      },
      {
        accessorKey: 'batch_status',
        header: 'Batch Status',
        size: 0,
        maxSize: 40,
        Cell: ({ row }) => (
          <SubmissionBatchDueDateStatusChip
            dueDate={row.original.property_batch?.submission.due_date ?? ''}
          />
        ),
      },
      {
        accessorKey: 'property_batch.submission.reporting_period_start_date',
        header: 'Period',
        size: 40,
        accessorFn: (row) =>
          getSubmissionPeriod(
            row.property_batch?.submission.reporting_period_start_date ?? '',
            row.property_batch?.submission.reporting_period_end_date ?? ''
          ),
      },
      {
        accessorKey: 'property_batch.submission.submission_name',
        header: 'Batch',
        size: 300,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <MuiLink
              component={Link}
              underline='hover'
              to={showSubmissionDetailsLink(
                row.original.property_batch?.submission.submission_id
              )}
            >
              {renderedCellValue}
            </MuiLink>
          );
        },
      },
      {
        id: 'actions',
        header: '',
        size: 10,
        Cell: ({ row }) => (
          <Grid
            container
            justifyContent={'flex-end'}
            sx={{
              color: theme.palette.grey[500],
              '& .MuiIconButton-root': {
                color: theme.palette.grey[700],
              },
            }}
          >
            <ActionsMenu>
              {rowActionOptions.map((option) => (
                <MenuItem
                  onClick={() => option.onClick && option.onClick(row)}
                  key={option.name}
                >
                  {option.label}
                </MenuItem>
              ))}
            </ActionsMenu>
          </Grid>
        ),
      },
    ],
    []
  );

  const table = useTable({
    data: propertyBatches,
    skeletonRowCount: 5,
    skeletonIdField: 'property_batch_id',
    skeletonBaseObj: {
      property_batch_id: 0,
      submission_status: '',
      validations_failed: 0,
      validations_total: 0,
      batch_status: '',
      datasets_total: 0,
      datasets_uploaded: 0,
      datasets_uploaded_ratio: 0,
      property_batch: {
        reviewedPackageReports: {
          aggregate: {
            count: 0,
          },
        },
        last_stage: [
          {
            property_batch_stage_id: 1,
          },
        ],
        properties_flattened_union: {
          entity_id: '',
          property_mapping: '',
        },
        is_valid: false,
        property_batch_id: 0,
        property_batch_stage_id: 0,
        updated_at_datetime: '',
        property: {
          property_id: 0,
          property_code: '',
          address: '',
          entity_id: '',
          fund: '',
          users: {
            aggregate: {
              count: 0,
            },
          },
          property_name: '',
        },
        submission: {
          reporting_period: '',
          submission_status_description: '',
          due_date: '',
          reporting_period_end_date: '',
          reporting_period_start_date: '',
          submission_id: 0,
          submission_name: '',
          submission_type: {
            submission_type_label: '',
          },
          provider: {
            provider_id: 0,
            provider_name: '',
          },
        },
      },
    },
    columns,
    enableRowSelection: false,
    enableSelectAll: false,
    enableBottomToolbar: true,
    enablePagination: true,
    enablePinning: true,
    muiTablePaperProps: {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      elevation: 0,
    },
    muiTableContainerProps: {
      sx: {
        flex: 1,
      },
    },
    muiTableBodyRowProps: { hover: true, sx: { background: 'white' } },
    state: {
      ...(tableState ?? {}),
      columnPinning: {
        right: ['actions'],
      },
    },
    manualPagination: true,
    onPaginationChange: (p) =>
      setTableState((s) => ({
        ...s,
        pagination: typeof p === 'function' ? p(s.pagination) : p,
      })),
    manualSorting: true,
    onSortingChange: (sort) =>
      setTableState((s) => ({
        ...s,
        sorting: typeof sort === 'function' ? sort(s.sorting) : sort,
      })),
    getRowId: (row) => row.property_batch_id?.toString() ?? '0',
    enableMultiSort: true,
    rowCount: propertyBatchesTotal?.aggregate?.count || 0,
  });

  if (propertyBatches && propertyBatches.length === 0) {
    return (
      <NoItemsFound title='No Submissions Yet'>
        There are no active property submission yet
      </NoItemsFound>
    );
  }

  return (
    <PanelStyled id='submissions'>
      <Table table={table} />
    </PanelStyled>
  );
};

MonitorSubmissionsTable.displayName = 'MonitorSubmissionsTable';

export default MonitorSubmissionsTable;
