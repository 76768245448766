import { GraphQLReturn, graphQLSelector } from '@cherre-frontend/data-fetching';
import { graphql } from 'react-relay';
import { recoilgetSubmissionUsersQuery } from './__generated__/recoilgetSubmissionUsersQuery.graphql';

export const getSubmissionUsers = graphQLSelector({
  query: graphql`
    query recoilgetSubmissionUsersQuery($property_id: Int) {
      preparers: sys_properties_roles_users(
        where: {
          property_id: { _eq: $property_id }
          is_active: { _eq: true }
          property_role: { property_role_slug: { _eq: "preparer" } }
        }
        order_by: { user: { sakura_user: { first_name: asc } } }
      ) {
        property_role_user_id
        user {
          sakura_user {
            email
            first_name
            last_name
          }
        }
      }
      reviewers_1: sys_properties_roles_users(
        where: {
          property_id: { _eq: $property_id }
          is_active: { _eq: true }
          property_role: { property_role_slug: { _eq: "reviewer_1" } }
        }
        order_by: { user: { sakura_user: { first_name: asc } } }
      ) {
        property_role_user_id
        user {
          sakura_user {
            email
            first_name
            last_name
          }
        }
      }
      reviewers_2: sys_properties_roles_users(
        where: {
          property_id: { _eq: $property_id }
          is_active: { _eq: true }
          property_role: { property_role_slug: { _eq: "reviewer_2" } }
        }
        order_by: { user: { sakura_user: { first_name: asc } } }
      ) {
        property_role_user_id
        user {
          sakura_user {
            email
            first_name
            last_name
          }
        }
      }
      reviewers_3: sys_properties_roles_users(
        where: {
          property_id: { _eq: $property_id }
          is_active: { _eq: true }
          property_role: { property_role_slug: { _eq: "reviewer_3" } }
        }
        order_by: { user: { sakura_user: { first_name: asc } } }
      ) {
        property_role_user_id
        user {
          sakura_user {
            email
            first_name
            last_name
          }
        }
      }
    }
  ` as GraphQLReturn<recoilgetSubmissionUsersQuery>,
  mapVariables: (propertyId?: number) => () => {
    if (!propertyId) {
      throw new Error('propertyId is required');
    }
    return {
      property_id: propertyId,
    };
  },
  mapResponse: (response) => response,
});
